import React, { useEffect, useState, useRef } from "react";
import Footer from "../../components/footer";
import Header from "../../components/header";
import { useLocation, useNavigate } from "react-router-dom";

// import './style.css'

function RegulatoryReports() {
    const SUMMARY_PROSPECTUS = 'https://secure.alpsinc.com/MarketingAPI/api/v1/Content/cloughglobal/clough-global-longshort-fund-spropdf-20230228.pdf'
    const PROSPECTUS = 'https://secure.alpsinc.com/MarketingAPI/api/v1/Content/cloughglobal/clough-global-longshort-fund-pro-20230228.pdf'
    const SAI = 'https://secure.alpsinc.com/MarketingAPI/api/v1/Content/cloughglobal/clough-global-longshort-fund-sai-20230228.pdf'
    const [activeReport, setActiveReport] = useState(SUMMARY_PROSPECTUS)

    return (
        <>
            <Header />
            <main id="mainContent">
                <div class="main_content">
                    <div class="reportnavbg">
                        <div class="reportnav" id="reportdiv">
                            <ul id="menu" class="noselect">
                                <li class="noselect" data-type="spropdf" data-location="cloax" data-id="cloax-spropdf-0" data-collected="true" data-startdate="02/28/2023" data-rangestartdate="Invalid date" data-title="Summary Prospectus" data-url="documents/clough-global-longshort-fund-spropdf-20230228.pdf" data-symbol="CLOAX" data-abbrev="SPROPDF">
                                    <div className={`title ${activeReport == SUMMARY_PROSPECTUS ? 'active' : ''}`} onClick={() => setActiveReport(SUMMARY_PROSPECTUS)}>Summary Prospectus</div>                                   
                                </li>
                                <li class="noselect" data-type="pro" data-location="cloax" data-id="cloax-pro-1" data-collected="true" data-startdate="02/28/2023" data-rangestartdate="Invalid date" data-title="Prospectus" data-url="documents/clough-global-longshort-fund-pro-20230228.pdf" data-symbol="CLOAX" data-abbrev="PRO">
                                    <div className={`title ${activeReport == PROSPECTUS ? 'active' : ''}`} onClick={() => setActiveReport(PROSPECTUS)}>Prospectus</div>
                                </li>
                                <li class="noselect" data-type="sai" data-location="cloax" data-id="cloax-sai-2" data-collected="true" data-startdate="02/28/2023" data-rangestartdate="Invalid date" data-title="SAI" data-url="documents/clough-global-longshort-fund-sai-20230228.pdf" data-symbol="CLOAX" data-abbrev="SAI">
                                    <div className={`title ${activeReport == SAI ? 'active' : ''}`} onClick={() => setActiveReport(SAI)}>SAI</div>
                                </li>
                            </ul>
                        </div>

                    </div>
                    {activeReport && activeReport !== '' &&
                        <div id="pdfcontent" class="content_section contain group">
                            <iframe id="pdf" src={activeReport} width="100%" height="1070px"></iframe>
                        </div>
                    }

                    <div class="pdfjs_block"></div>


                    <div class="clear"></div>
                </div>
            </main>

            <Footer />
        </>
    );
}

export default RegulatoryReports;