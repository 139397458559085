import React, { useEffect, useState } from "react";
import Footer from "../../components/footer";
import Header from "../../components/header";

function Glossary() {
    const [scrollVisible, setScrollVisible] = useState(null);
    
    useEffect(() => {
        window.addEventListener('scroll', () => setScrollVisible(window.pageYOffset))
    }, [])

    return (
        <>
            <Header />
            <main id="mainContent">

                <div class="main_content">

                    <div class={`hero-short hero-short__img-7 ${scrollVisible > 10 ? 'hero-short--collapsed' : ''}`}>
                        <div class="container">

                            <div class="col span_6_of_10 lg-full">
                                <h2 class="hero-short__ticker--static mod1">Glossary</h2>
                            </div>

                        </div>
                    </div>



                    <div class="container inner-content">


                        <b class="mod1">Annualized Return</b>
                        <p>Expressed as a percentage, annualized return calculates the return of a mutual fund or other investment over the period of an average year within a given duration. For example, an annualized ten year-return calculates the average annual return required to result in the 10-year cumulative return.</p>

                        <b class="mod1">Asked or Offering Price</b>
                        <p>The price at which a mutual fund's shares can be purchased. The asked or offering price means the current net asset value (NAV) per share plus sales charge, if any. For a no-load fund, the asked price is the same as the NAV.</p>

                        <b class="mod1">Basis Point</b>
                        <p>The smallest measure used for quoting yields on bonds and notes. One basis point is 0.01% of yield. So, if the Federal Reserve increases rates 50 basis points, or a bond's yield changes 50 basis points, that equates to 0.5% or 1/2 of 1%.</p>

                        <b class="mod1">Capital Gains Distributions</b>
                        <p>A taxable distribution paid to shareholders when any investments in their mutual fund's portfolio are sold at a profit. Realized Capital Gains are when stock or bond holdings are sold at a profit. Unrealized Capital Gains represent an increase in the market value of a mutual fund.</p>

                        <b class="mod1">Closed-End Fund</b>
                        <p>A fund with a fixed number of shares outstanding, and one which does not redeem shares the way a typical mutual fund does. Closed-end funds behave more like stock than open-end funds: closed-end funds issue a fixed number of shares to the public in an initial public offering, after which time shares in the fund are bought and sold on a stock exchange, and they are not obligated to issue new shares or redeem outstanding shares as open-end funds are. The price of a share in a closed-end fund is determined entirely by market demand, so shares can either trade below their net asset value ("at a discount") or above it ("at a premium").</p>

                        <b class="mod1">Distributions</b>
                        <p>The income or capital gain made by a mutual fund that is paid to the fund's investors.</p>

                        <b class="mod1">Distribution Yield</b>
                        <p>The ratio of all the distributions a fund paid in the past 12 months divided by the current share price of the fund.</p>

                        <b class="mod1">Dividend Reinvestment</b>
                        <p>The option to reinvest cash dividends by purchasing additional shares or fractional shares on the dividend payment date.</p>

                        <b class="mod1">Free cash flow yield</b>
                        <p>A ratio calculated by dividing a company's free cash flow by its market capitalization.</p>

                        <b class="mod1">Net Asset Value - (NAV)</b>
                        <p>In the context of mutual funds, the total value of the fund's portfolio less liabilities. The NAV is usually calculated on a daily basis.</p>

                        <b class="mod1">Prospectus</b>
                        <p>A legal document that provides full disclosure of all information investors need to make a sound decision about an investment. Financial institutions file prospectuses with the Securities and Exchange Commission (SEC).</p>

                        <b class="mod1">Premium/Discount</b>
                        <p>The percentage difference between the net asset value of a fund and the market price.</p>

                        <b class="mod1">Price/Earnings Ratio</b>
                        <p>Represents equity securities within the Fund's portfolio, and is not intended to demonstrate Fund growth, income earned by the Fund, or distributions made by the Fund.</p>





                        <div class="spacer" />

                    </div>

                </div>

            </main>
            <Footer />
        </>
    );
}

export default Glossary;