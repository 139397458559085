import React, { useEffect, useState, useRef } from "react";
import Footer from "../../components/footer";
import Header from "../../components/header";
import AboutUs from "../../modal-components/AboutUs";
import { teamBio } from '../../utils/teamBio';
import UseIsInViewport from '../../utils/useInViewPort'
import ChartComponents from "./ChartComponents";

import './style.css'

function Advisor() {
    const ABOUT_CLOUGH_CAPITAL = 'About Clough Capital'
    const THE_INVESTMENT_TEAM = 'The Investment Team'
    const OUR_PROCESS = 'Our Process'
    const [bioModal, setBioModal] = useState(false)
    const [activeIndex, setActiveIndex] = useState()
    const [scrollActive, setScrollActive] = useState(false)
    const [dropdownActive, setDropdownActive] = useState(false)
    const [scrollVisible, setScrollVisible] = useState(false);
    const [activeSection, setActiveSection] = useState(ABOUT_CLOUGH_CAPITAL)
    const ref1 = useRef(null);
    const ref2 = useRef(null);
    const ref3 = useRef(null);

    const isInViewport1 = UseIsInViewport(ref1);
    const isInViewport2 = UseIsInViewport(ref2);
    const isInViewport3 = UseIsInViewport(ref3);

    // check graph YAxis domain when updating these values 
    const AUM = "1.3"
    const HEDGE_FUND = "519"
    const CLOSED_END_FUND = "767"
    const EXCHANGE_TRADE_FUND = "50"
    const AUM_DATE = "06/30/2024"
    const AUM_FORMAT_DATE = "June 30, 2024"

    useEffect(() => {
        window.addEventListener('scroll', () => setScrollVisible(window.pageYOffset))
    }, [])

    useEffect(() => {
        if (!scrollActive) {
            if (isInViewport1) {
                setActiveSection(ABOUT_CLOUGH_CAPITAL)
            } else if (isInViewport2) {
                setActiveSection(THE_INVESTMENT_TEAM)
            } else if (isInViewport3) {
                setActiveSection(OUR_PROCESS)
            }
        }
    }, [scrollActive, isInViewport1, isInViewport2, isInViewport3])

    const handleClickScroll = async (id, sectionTitle) => {
        await setScrollActive(true)
        var element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ block: 'center', behavior: 'smooth' });
            setActiveSection(sectionTitle)
        }
        setDropdownActive(false)
        setTimeout(() => {
            setScrollActive(false)
        }, 1000)
    };

    return (
        <>
            <Header />
            <main id="mainContent">
                <div class="main_content">
                    <div class="hero-short hero-short__img-3">
                        <div class="container">

                            <div class="col span_6_of_10 lg-full">
                                <h2 class="hero-short__ticker--static">The Advisor</h2>
                            </div>

                        </div>
                        <div class={`subnav__wrapper ${scrollVisible > 150 ? 'sticky' : ''}`}>
                            <div class="container">

                                <ul class="subnav">
                                    <li class={`subnav__item ${activeSection == ABOUT_CLOUGH_CAPITAL ? 'active' : ''}`}>
                                        <a class="subnav__link" onClick={() => handleClickScroll('about2', ABOUT_CLOUGH_CAPITAL)}>About Clough Capital</a>
                                    </li>
                                    <li class={`subnav__item ${activeSection == THE_INVESTMENT_TEAM ? 'active' : ''}`}>
                                        <a class="subnav__link" onClick={() => handleClickScroll('the-team2', THE_INVESTMENT_TEAM)}>The Investment Team</a>
                                    </li>
                                    <li class={`subnav__item ${activeSection == OUR_PROCESS ? 'active' : ''}`}>
                                        <a class="subnav__link" onClick={() => handleClickScroll('our_process2', OUR_PROCESS)}>Our Process</a>
                                    </li>
                                </ul>
                                <div className="subnav_mobile-dropdown">
                                    <div class={`styledSelect ${dropdownActive ? 'active' : ''}`} aria-role="select" onClick={() => setDropdownActive(!dropdownActive)}>
                                        {activeSection ? activeSection : 'SELECT A Sub Section'}
                                    </div>
                                    {dropdownActive &&
                                        <ul class="options subnavDropdown" style={{}}>
                                            <li rel="about2" onClick={() => handleClickScroll('about2', ABOUT_CLOUGH_CAPITAL)}>About Clough Global</li>
                                            <li rel="the-team2" onClick={() => handleClickScroll('the-team2', THE_INVESTMENT_TEAM)}>The Team</li>
                                            <li rel="our_process2" onClick={() => handleClickScroll('our_process2', OUR_PROCESS)}>Our Process</li>
                                        </ul>
                                    }
                                </div>

                            </div>
                        </div>

                    </div>
                    <div class="container inner-content clearfix video-element" ref={ref1}>

                        <div id="about2" class="waypoint__subnav"></div>
                        <h3>About Clough Capital</h3>

                        <div class="row inv_row">

                            <h3 class="mod1">The Advisor: Clough Capital</h3>
                            <br /><br />
                            <p>Clough Capital Partners L.P. ("Clough Capital" or the "Firm"), is a globally-focused investment management firm with ${AUM} billion in client assets under management (as of {AUM_FORMAT_DATE}). Clough Capital was founded in 2000 by Charles I. (“Chuck”) Clough Jr., after a career culminating as Chief Global Investment Strategist at Merrill Lynch &amp; Co.</p>

                            {/* <p>Clough Capital invests both long-short and long-only in public equity, fixed income and alternative markets across the globe. The Firm’s investment strategies, in addition to global long-short, include sector specific funds, both by geography and by industry. The Firm delivers those strategies through various investment vehicles including institutional separate accounts, pooled funds and mutual funds (both open and closed end).</p> */}

                            <p>Throughout the Firm's history, Clough Capital has been fortunate to have attracted and retained a talented team of professionals. Currently, there are 10 dedicated individuals working on behalf of our clients.</p>

                            <br />
                        </div>

                        <div class="row" id="aum-row">
                            {/* <div class="col span_5_of_11" id="aum-wrapper"><div class="chartjs-size-monitor"><div class="chartjs-size-monitor-expand"><div class=""></div></div><div class="chartjs-size-monitor-shrink"><div class=""></div></div></div>
                                <canvas data-action="aum" style={{ display: "block", height: "355px", width: "521px" }} width="651" height="443" class="chartjs-render-monitor"></canvas>
                            </div> */}
                            <div class="col span_5_of_11 ">
                                <div className="clough-chart-wrapper" style={{ height: "350px" }}>
                                    <ChartComponents hedgeFund={HEDGE_FUND} closedEndFund={CLOSED_END_FUND} etf={EXCHANGE_TRADE_FUND}/>
                                </div>
                            </div>
                            <div class="col span_1_of_11 "></div>
                            <div class="col span_5_of_11 ">
                                <div class="bar_chart_legend mobile"></div>
                                <p class="quote" data-action="aum--quote">"Clough Capital is a Boston-based investment advisory firm which manages {AUM} billion in assets: {HEDGE_FUND} million in hedge fund and institutional accounts; {CLOSED_END_FUND} million in closed-end mutual funds; {EXCHANGE_TRADE_FUND} million in exchange traded funds."</p>

                                <date>as of <span data-key="asofdate">{AUM_DATE}</span></date>
                                <div className="spacer" />
                                <div class="bar_chart_legend desktop">
                                    <p class="legend a">Hedge Fund &amp; Institutional Accounts</p>
                                    <p class="legend b">Closed-End Mutual Funds</p>
                                    <p class="legend c">Exchange-Traded Funds</p>
                                    {/* <p class="legend c">Open-end Mutual Funds</p> */}
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <h3 class="mod1">Firm History</h3>
                            <br /><br />
                        </div>

                        <div class="row">

                            <div class="col span_5_of_11 ">
                                <p>Throughout the Firm's history, Clough Capital has been fortunate to have attracted and retained a talented team of professionals. Currently, there are 10 dedicated individuals working on behalf of our clients. Clough Capital employs 4 highly experienced investment professionals and 6 non-investment professionals.</p>
                                <p>Clough Capital has comprehensive infrastructure including research, trading, compliance, technology, and client services. The Firm is a Registered Investment Advisor pursuant to the Investment Advisors Act of 1940.</p>
                            </div>
                            <div class="col span_1_of_11 "></div>
                            <div class="col span_5_of_11 ">
                                <p class="quote">"Currently, there are 10 dedicated individuals working on behalf of our clients. Clough Capital employs 4 highly experienced investment professionals and 6 non-investment professionals."</p>

                            </div>
                        </div>

                    </div>
                    <div class="container clearfix" ref={ref2}>
                        <div id="the-team2" class="waypoint__subnav"></div>
                        <h3>The Investment Team</h3>
                        <br /><br />
                        <div id="bioTarget">
                            <div class="container bioSection">
                                {teamBio?.map((item, idx) => {
                                    return (
                                        <div class="col span_1_of_2" style={{ marginLeft: (idx % 2 == 0) ? '0' : '3%' }}>
                                            <img id={`modal${idx + 1}`} src={item?.image} alt={item?.name} onClick={() => { setActiveIndex(idx); setBioModal(true) }} />
                                            <h4 dangerouslySetInnerHTML={{ __html: item?.name }} />
                                            <h5 dangerouslySetInnerHTML={{ __html: item?.role }} />
                                            <a onClick={() => { setActiveIndex(idx); setBioModal(true) }}>Learn More</a>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                    <div class="container clearfix" ref={ref3}>
                        <div id="our_process2" class="waypoint__subnav"></div>
                        <h3>Our Process</h3>

                        <h3 class="mod1">THE INVESTMENT PROCESS</h3>
                        <br /><br />
                        <div class="row">

                            <div id="chart_inv_process">

                                <div class="stage" style={{ width: "630px" }}>


                                    <div class="circle_roll roll_1">
                                        <content><b>IDEA GENERATION</b>
                                            Identify industry trends, understand profit and credit cycles, demographics, regulatory framework, develop themes </content>
                                        <bg class="roll_1_bg"></bg>
                                    </div>

                                    <div class="circle_roll roll_2">
                                        <content><b>SECURITY ANALYSIS</b>
                                            Company meetings, financial statement analysis, valuation work, examine business models, identify catalysts</content>
                                        <bg class="roll_2_bg"></bg>
                                    </div>

                                    <div class="circle_roll roll_3">
                                        <content><b>INVESTMENT REVIEW</b>
                                            Research meetings, interactive dialogue, security recommendations</content>
                                        <bg class="roll_3_bg"></bg>
                                    </div>

                                    <div class="circle_roll roll_4">
                                        <content><b>PORTFOLIO CONSTRUCTION</b>
                                            Security selection, sizing of positions, long and short exposure targeting, geographic weighting</content>
                                        <bg class="roll_4_bg"></bg>
                                    </div>

                                    <div class="circle_roll roll_5">
                                        <content><b>RISK OVERSIGHT</b>
                                            Ongoing portfolio monitoring, risk committee, portfolio analytics, stress testing</content>
                                        <bg class="roll_5_bg"></bg>
                                    </div>


                                    <div class="circle_blue">Investment Portfolio</div>
                                    <img class="arrows rotate-animation" src="assets/images/inv_process_arrows.svg" />
                                    <img class="spokes" src="assets/images/inv_process_arrows_2.svg" />

                                </div>

                            </div>

                        </div>

                        <h3 class="mod1">THE RESEARCH PROCESS</h3>
                        <br /><br /><br />

                        <div class="row advisor_icons">
                            <div class="col span_1_of_3 ">
                                <img src="assets/images/advisor_icon_1.svg" width="120px" height="120px" />
                                <b><i>Identify Shortages</i></b>
                                <ul>
                                    <li>Supply shortages emerge due to inadequate investment, which leads to higher prices</li>
                                    <li>Higher prices in turn lead to higher cash flows and higher returns on investment</li>
                                    <li>Identify those companies best positioned to take advantage of shortages</li>
                                </ul>
                            </div>
                            <div class="col span_1_of_3 ">
                                <img src="assets/images/advisor_icon_2.svg" width="120px" height="120px" />
                                <b><i>Identify Global Geographic <br />Growth Opportunities</i></b>
                                <ul>
                                    <li>Stock market valuations are deemed to be inexpensive</li>
                                    <li>A catalyst for growth is present</li>
                                    <li>The currency is expected to strengthen relative to the U.S. dollar, enhancing U.S. dollar returns</li>
                                </ul>
                            </div>
                            <div class="col span_1_of_3 ">
                                <img src="assets/images/advisor_icon_3.svg" width="120px" height="120px" />
                                <b><i>Identify Unique Global Credit <br />Market Opportunities</i></b>
                                <ul>
                                    <li>Changing economic fundamentals may be a positive factor for both income and price appreciation</li>
                                    <li>An attractive yield curve exists to react to changing economic fundamentals</li>
                                    <li>For international securities, the currency is expected to strengthen relative to the U.S. dollar, enhancing U.S. dollar returns</li>
                                </ul>
                            </div>


                        </div>


                        <div className="spacer" />
                    </div>
                </div>
                <div class="spacer" />
            </main>
            <Footer />
            {bioModal &&
                <AboutUs
                    setClosehandler={setBioModal}
                    activeIndex={activeIndex}
                    data={teamBio}
                />
            }
        </>
    );
}

export default Advisor;
