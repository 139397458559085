import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { useQuery } from "@apollo/client";
import Footer from "../../components/footer";
import Header from "../../components/header";
import SliderShow from './components/SliderShow'
import downLoadIcon from "../../assets/images/download_icon.svg"
import { useLocation, useNavigate } from "react-router-dom";
import { DAILY_NAV, RESOURCES_QUERY } from '../../utils/queries'
import { getUniqueFunds, getQuarterSite } from '../../utils/helper'
import './style.css'

function Home() {
    const OPEN_END_FUNDS = 'open_end_funds'
    const CLOSE_END_FUNDS = 'close_end_funds'
    const location = useLocation()
    const navigate = useNavigate()
    const [activeFunds, setActiveFunds] = useState(CLOSE_END_FUNDS)
    const { loading: GLVLoading, error: GLV, data: GLVData } = useQuery(DAILY_NAV, { variables: { ticker: "GLV" }, });
    const { loading: GLQLoading, error: GLQ, data: GLQData } = useQuery(DAILY_NAV, { variables: { ticker: "GLQ" }, });
    const { loading: GLOLoading, error: GLO, data: GLOData } = useQuery(DAILY_NAV, { variables: { ticker: "GLO" }, });

    const { data: glvResourceData, loading: glvResourceLoading, error: glvResourceError } = useQuery(RESOURCES_QUERY, { variables: { ticker: 'GLV', documentType: [{ type: 'fundnews' }, { type: 'monthly_factsheet' }], documentTypes: [{ type: 'fundnews' }, { type: 'monthly_factsheet' }] } });
    const { data: gloResourceData, loading: gloResourceLoading, error: gloResourceError } = useQuery(RESOURCES_QUERY, { variables: { ticker: 'GLO', documentType: [{ type: 'fundnews' }, { type: 'monthly_factsheet' }], documentTypes: [{ type: 'fundnews' }, { type: 'monthly_factsheet' }] } });
    const { data: glqResourceData, loading: glqResourceLoading, error: glqResourceError } = useQuery(RESOURCES_QUERY, { variables: { ticker: 'GLQ', documentType: [{ type: 'fundnews' }, { type: 'monthly_factsheet' }], documentTypes: [{ type: 'fundnews' }, { type: 'monthly_factsheet' }] } });
    // const { data: glsResourceData, loading: glsResourceLoading, error: glsResourceError } = useQuery(RESOURCES_QUERY, { variables: { ticker: 'GLS', documentType: [{ type: 'fundnews' }, { type: 'monthly_factsheet' }], documentTypes: [{ type: 'fundnews' }, { type: 'monthly_factsheet' }] } });
    // const { data: cloughBannerData, loading: cloughBannerLoading, error: cloughBannerError } = useQuery(RESOURCES_QUERY, { variables: { ticker: 'CLOUGH', documentType: [{ type: 'banner' }], documentTypes: [{ type: 'banner' }] } });

    useEffect(() => {
        if (location?.pathname && location.pathname.split('/')?.length > 2) {
            let activeId = location.pathname.split('/')[2]
            setActiveFunds(activeId)
            setTimeout(() => {
                navigate('/')
            }, 1500)
        } else {
            setActiveFunds(CLOSE_END_FUNDS)
        }
    }, [])

    const getFundNews = (data, type) => {
        let resource = {}
        type = type || 'fundnews'
        if (data?.resources?.completeFundData?.length > 0) {
            let documentType = data?.resources?.completeFundData?.filter((x) => x.type == type)
            let unique = getUniqueFunds(documentType)
            if (unique?.length > 0) {
                resource = unique[0]?.funds[0]
            }
        }
        return resource
    }

    return (
        <>
            <Header />
            <main id="mainContent">
                <div class="main_content">
                    <div style={{ position: "relative", marginTop: "100px" }}>
                        {/* <SliderShow quarterSite={getQuarterSite(cloughBannerData, 'Quarterly Commentary')} /> */}
                        <SliderShow />
                    </div>
                    {/* {glvResourceData && getQuarterSite(cloughBannerData, 'Clough Capital Partners Statement')?.id ?
                        <div class="callout">
                            <div class="container">
                                <a href={getQuarterSite(cloughBannerData, 'Clough Capital Partners Statement')?.id ? `${process.env.REACT_APP_API_LINK}download_resource/?id=${getQuarterSite(cloughBannerData, 'Clough Capital Partners Statement')?.id}&ticker=${getQuarterSite(cloughBannerData, 'Clough Capital Partners Statement')?.ticker}` : ''} target="_blank">
                                    <span>{getQuarterSite(cloughBannerData, 'Clough Capital Partners Statement')?.friendlyName}</span>
                                </a>
                            </div>
                        </div>
                        : ''
                    } */}
                    <div class="spacer" />

                    <div class="container">
                        <div class="row">
                            <div class="col span_8_of_12">

                                <div class="tabs">

                                    <ul class="tabs__list">
                                        {/* <li class="tabs__list-item">
                                            <div class={`tabs__tab ${activeFunds == OPEN_END_FUNDS ? 'active' : ''}`} onClick={() => activeFunds !== OPEN_END_FUNDS && setActiveFunds(OPEN_END_FUNDS)}>open-end funds</div>
                                        </li> */}
                                        <li class="tabs__list-item">
                                            <div class={`tabs__tab ${activeFunds == CLOSE_END_FUNDS ? 'active' : ''}`} onClick={() => activeFunds !== CLOSE_END_FUNDS && setActiveFunds(CLOSE_END_FUNDS)}>closed-end funds</div>
                                        </li>
                                    </ul>
                                    {/* {activeFunds == OPEN_END_FUNDS &&
                                        <div class="">

                                            <div class="tableHorizontalScroll">

                                                <table class="table--mod-1 table_alt1">
                                                    <tbody><tr>
                                                        <th>
                                                            Daily Data <date>as of <span>
                                                                {GLVData?.fund?.dailyData?.asOfDate ? moment(GLVData?.fund?.dailyData?.asOfDate).format("MM/DD/yyyy") : ''}
                                                            </span></date>
                                                        </th>
                                                        <th>Ticker</th>
                                                        <th>NAV</th>
                                                        <th>NAV Change</th>
                                                        <th>Fact Sheet</th>
                                                    </tr>

                                                        <tr>
                                                            <td>Clough Global Long/Short Fund - Class <span>I</span></td>
                                                            <td>CLOIX</td>
                                                            <td>$9.12</td>
                                                            <td>$0.00</td>
                                                            <td>
                                                                {glsResourceData && getFundNews(glsResourceData, 'monthly-factsheet')?.id ?
                                                                    <a href={getFundNews(glsResourceData, 'monthly-factsheet')?.id ? `${process.env.REACT_APP_API_LINK}download_resource/?id=${getFundNews(glsResourceData, 'monthly-factsheet')?.id}&ticker=${getFundNews(glsResourceData, 'monthly-factsheet')?.ticker}` : ''} target="_blank">
                                                                        <img src={downLoadIcon} width="22" height="22" />
                                                                    </a>
                                                                    : '-'
                                                                }
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td>Clough Global Long/Short Fund - Class <span>Inv</span></td>
                                                            <td>CLOAX</td>
                                                            <td>$8.95</td>
                                                            <td>$0.00</td>
                                                            <td>
                                                                {glsResourceData && getFundNews(glsResourceData, 'monthly-factsheet')?.id ?
                                                                    <a href={getFundNews(glsResourceData, 'monthly-factsheet')?.id ? `${process.env.REACT_APP_API_LINK}download_resource/?id=${getFundNews(glsResourceData, 'monthly-factsheet')?.id}&ticker=${getFundNews(glsResourceData, 'monthly-factsheet')?.ticker}` : ''} target="_blank">
                                                                        <img src={downLoadIcon} width="22" height="22" />
                                                                    </a>
                                                                    : '-'
                                                                }
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td>Clough Global Long/Short Fund - Class <span>A</span></td>
                                                            <td>CLOVX</td>
                                                            <td>$9.00</td>
                                                            <td>$0.00</td>
                                                            <td>
                                                                {glsResourceData && getFundNews(glsResourceData, 'monthly-factsheet')?.id ?
                                                                    <a href={getFundNews(glsResourceData, 'monthly-factsheet')?.id ? `${process.env.REACT_APP_API_LINK}download_resource/?id=${getFundNews(glsResourceData, 'monthly-factsheet')?.id}&ticker=${getFundNews(glsResourceData, 'monthly-factsheet')?.ticker}` : ''} target="_blank">
                                                                        <img src={downLoadIcon} width="22" height="22" />
                                                                    </a>
                                                                    : '-'
                                                                }
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td>Clough Global Long/Short Fund - Class <span>C</span></td>
                                                            <td>CLOCX</td>
                                                            <td>$8.45</td>
                                                            <td>$0.00</td>
                                                            <td>
                                                                {glsResourceData && getFundNews(glsResourceData, 'monthly-factsheet')?.id ?
                                                                    <a href={getFundNews(glsResourceData, 'monthly-factsheet')?.id ? `${process.env.REACT_APP_API_LINK}download_resource/?id=${getFundNews(glsResourceData, 'monthly-factsheet')?.id}&ticker=${getFundNews(glsResourceData, 'monthly-factsheet')?.ticker}` : ''} target="_blank">
                                                                        <img src={downLoadIcon} width="22" height="22" />
                                                                    </a>
                                                                    : '-'
                                                                }
                                                            </td>
                                                        </tr>

                                                    </tbody>

                                                </table>

                                            </div>

                                            <div class="tableOverflowIndicator">
                                                &larr; Swipe to see additional data &rarr;
                                            </div>

                                        </div>
                                    } */}
                                    {activeFunds == CLOSE_END_FUNDS &&
                                        <div class="">

                                            <div class="tableHorizontalScroll">

                                                <table class="table--mod-1 table_alt1">

                                                    <tr>
                                                        <th>
                                                            Daily Data <date>as of <span>
                                                                {GLVData?.fund?.dailyData?.asOfDate ? moment(GLVData?.fund?.dailyData?.asOfDate).format("MM/DD/yyyy") : ''}</span></date>
                                                        </th>
                                                        <th>Ticker</th>
                                                        <th>NAV</th>
                                                        <th>Market Price</th>
                                                        <th>Premium/Discount</th>
                                                        <th>Distribution Yield</th>
                                                        <th>Fact Sheet</th>

                                                    </tr>

                                                    <tr>
                                                        <td><Link to="/glv">Clough Global Dividend and Income Fund</Link></td>
                                                        <td>GLV</td>
                                                        <td>{GLVData?.fund?.dailyData?.NAV ? `$${GLVData?.fund?.dailyData?.NAV}` : '-'}</td>
                                                        <td>{GLVData?.fund?.dailyData?.marketPrice ? `$${GLVData?.fund?.dailyData?.marketPrice}` : '-'}</td>
                                                        <td>{GLVData?.fund?.dailyData?.premiumDiscount ? `${(GLVData?.fund?.dailyData?.premiumDiscount * 100).toFixed(2)}%` : '-'}</td>
                                                        <td>{GLVData?.fund?.dailyData?.distributionRate ? `${(GLVData?.fund?.dailyData?.distributionRate * 100).toFixed(2)}%` : '-'}</td>
                                                        <td>
                                                            {glvResourceData && getFundNews(glvResourceData, 'monthly_factsheet')?.id ?
                                                                <a href={getFundNews(glvResourceData, 'monthly_factsheet')?.id ? `${process.env.REACT_APP_API_LINK}download_resource/?id=${getFundNews(glvResourceData, 'monthly_factsheet')?.id}&ticker=${getFundNews(glvResourceData, 'monthly_factsheet')?.ticker}` : ''} target="_blank">
                                                                    <img src={downLoadIcon} width="22" height="22" />
                                                                </a>
                                                                : '-'
                                                            }
                                                        </td>

                                                    </tr>

                                                    <tr>
                                                        <td><Link to="/glq">Clough Global Equity Fund</Link></td>
                                                        <td>GLQ</td>
                                                        <td>{GLQData?.fund?.dailyData?.NAV ? `$${GLQData?.fund?.dailyData?.NAV}` : '-'}</td>
                                                        <td>{GLQData?.fund?.dailyData?.marketPrice ? `$${GLQData?.fund?.dailyData?.marketPrice}` : '-'}</td>
                                                        <td>{GLQData?.fund?.dailyData?.premiumDiscount ? `${(GLQData?.fund?.dailyData?.premiumDiscount * 100).toFixed(2)}%` : '-'}</td>
                                                        <td>{GLQData?.fund?.dailyData?.distributionRate ? `${(GLQData?.fund?.dailyData?.distributionRate * 100).toFixed(2)}%` : '-'}</td>
                                                        <td>
                                                            {glqResourceData && getFundNews(glqResourceData, 'monthly_factsheet')?.id ?
                                                                <a href={getFundNews(glqResourceData, 'monthly_factsheet')?.id ? `${process.env.REACT_APP_API_LINK}download_resource/?id=${getFundNews(glqResourceData, 'monthly_factsheet')?.id}&ticker=${getFundNews(glqResourceData, 'monthly_factsheet')?.ticker}` : ''} target="_blank">
                                                                    <img src={downLoadIcon} width="22" height="22" />
                                                                </a>
                                                                : '-'
                                                            }
                                                        </td>

                                                    </tr>

                                                    <tr>
                                                        <td><Link to="/glo">Clough Global Opportunities Fund</Link></td>
                                                        <td>GLO</td>
                                                        <td>{GLOData?.fund?.dailyData?.NAV ? `$${GLOData?.fund?.dailyData?.NAV}` : '-'}</td>
                                                        <td>{GLOData?.fund?.dailyData?.marketPrice ? `$${GLOData?.fund?.dailyData?.marketPrice}` : '-'}</td>
                                                        <td>{GLOData?.fund?.dailyData?.premiumDiscount ? `${(GLOData?.fund?.dailyData?.premiumDiscount * 100).toFixed(2)}%` : '-'}</td>
                                                        <td>{GLOData?.fund?.dailyData?.distributionRate ? `${(GLOData?.fund?.dailyData?.distributionRate * 100).toFixed(2)}%` : '-'}</td>
                                                        <td>
                                                            {gloResourceData && getFundNews(gloResourceData, 'monthly_factsheet')?.id ?
                                                                <a href={getFundNews(gloResourceData, 'monthly_factsheet')?.id ? `${process.env.REACT_APP_API_LINK}download_resource/?id=${getFundNews(gloResourceData, 'monthly_factsheet')?.id}&ticker=${getFundNews(gloResourceData, 'monthly_factsheet')?.ticker}` : ''} target="_blank">
                                                                    <img src={downLoadIcon} width="22" height="22" />
                                                                </a>
                                                                : '-'
                                                            }
                                                        </td>
                                                    </tr>

                                                </table>
                                            </div>

                                            <div class="tableOverflowIndicator">
                                                &larr; Swipe to see additional data &rarr;
                                            </div>

                                        </div>
                                    }
                                </div>
                            </div>

                            <div class="col span_4_of_12 ">
                                <h3>Fund News</h3>

                                <br />
                                <div>
                                    <div class="news-record">
                                        {getFundNews(glvResourceData) && getFundNews(glvResourceData)?.id &&
                                            <div class="news_item">
                                                <div class="news__headline">{getFundNews(glvResourceData)?.createdAt ? moment(getFundNews(glvResourceData)?.createdAt).format("MM/DD/yyyy") : ''}</div>
                                                <a href={getFundNews(glvResourceData)?.id ? `${process.env.REACT_APP_API_LINK}download_resource/?id=${getFundNews(glvResourceData)?.id}&ticker=${getFundNews(glvResourceData)?.ticker}` : ''} class="" target="_blank">
                                                    <span>{getFundNews(glvResourceData)?.friendlyName}</span>
                                                </a>
                                                <br /><br />
                                            </div>
                                        }
                                        {getFundNews(gloResourceData) && getFundNews(gloResourceData)?.id &&
                                            <div class="news_item">
                                                <div class="news__headline">{getFundNews(gloResourceData)?.createdAt ? moment(getFundNews(gloResourceData)?.createdAt).format("MM/DD/yyyy") : ''}</div>
                                                <a href={getFundNews(gloResourceData)?.id ? `${process.env.REACT_APP_API_LINK}download_resource/?id=${getFundNews(gloResourceData)?.id}&ticker=${getFundNews(gloResourceData)?.ticker}` : ''} class="" target="_blank">
                                                    <span>{getFundNews(gloResourceData)?.friendlyName}</span>
                                                </a>
                                                <br /><br />
                                            </div>
                                        }
                                        {getFundNews(glqResourceData) && getFundNews(glqResourceData)?.id &&
                                            <div class="news_item">
                                                <div class="news__headline">{getFundNews(glqResourceData)?.createdAt ? moment(getFundNews(glqResourceData)?.createdAt).format("MM/DD/yyyy") : ''}</div>
                                                <a href={getFundNews(glqResourceData)?.id ? `${process.env.REACT_APP_API_LINK}download_resource/?id=${getFundNews(glqResourceData)?.id}&ticker=${getFundNews(glqResourceData)?.ticker}` : ''} class="" target="_blank">
                                                    <span>{getFundNews(glqResourceData)?.friendlyName}</span>
                                                </a>
                                                <br /><br />
                                            </div>
                                        }
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="spacer" />
            </main>
            <Footer />
        </>
    );
}

export default Home;