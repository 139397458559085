import React, { useState, useEffect } from "react";
import ContactUs from "../modal-components/ContactUs";
import { useQuery, useLazyQuery } from "@apollo/client";
import { RESOURCES_QUERY } from '../utils/queries'
import { getUniqueFunds } from '../utils/helper'
import { Link, useLocation } from "react-router-dom";
import arrowRight from '../assets/images/arrow-right.svg'

function Footer() {
    const [scrollVisible, setScrollVisible] = useState(false);
    const [contactUsForm, setContactUsForm] = useState(false);
    const [resources, setResources] = useState(null);
    const location = useLocation()
    // const { data: glsResourceData, loading: glsResourceLoading, error: glsResourceError } = useQuery(RESOURCES_QUERY,
    //     { variables: { ticker: 'GLS', documentType: [{ type: 'annual_report' }, { type: 'semi_annual_report' }, { type: 'prospectus' }], documentTypes: [{ type: 'annual_report' }, { type: 'semi_annual_report' }, { type: 'prospectus' }] } }
    // );

    const [
        fetchResources,
        { data: resourceData, loading: resourceLoading, error: resourceError },
    ] = useLazyQuery(RESOURCES_QUERY);

    useEffect(() => {
        window.addEventListener('scroll', () => setScrollVisible(window.pageYOffset))
    }, [])

    useEffect(() => {
        let params = location?.pathname?.split('/')
        let fundslist = ['GLV', 'GLQ', 'GLO']
        let ticker = (params?.length > 1 && params[params?.length - 1] && fundslist?.includes(params[params?.length - 1]?.toUpperCase())) ? params[params?.length - 1]?.toUpperCase() : 'GLV'
        fetchResources({ variables: { ticker: ticker, documentType: [{ type: 'annual_report' }, { type: 'semi_annual_report' }], documentTypes: [{ type: 'annual_report' }, { type: 'semi_annual_report' }] } });
    }, [location])

    useEffect(() => {
        if (resourceData?.resources?.completeFundData?.length > 0) {
            let resources = {}
            resourceData?.resources?.completeFundData?.map((data) => {
                resources[data.type] = getUniqueFunds(data.funds)
            })
            setResources(resources)
        }
    }, [resourceData])

    const backToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    return (
        <>
            <footer id="mainFooter">

                <div class="footer-nav">
                    <div class="container clearfix">

                        <div class="col span_8_of_12 footer__call-us">
                            <div class="footer_container">CLOSED-END FUNDS<br /><a href="tel:18554256844" alt="CLOSED-END FUNDS 1 855 425 6844" target="_blank">1-855-425-6844</a>  </div>

                            {/* <div class="footer_line"></div>
                            <div class="footer_container">OPEN-END FUNDS<br /><a href="tel:18554256844" alt="OPEN-END FUNDS 1 855 425 6844" target="_blank">1-855-425-6844</a>
                            </div> */}

                            <div class="footer_line"></div>
                            <div class="footer_container">ADVISOR<br /><a href="tel:6172043400" alt="ADVISOR 617 204 3400" target="_blank">617-204-3400</a>
                            </div>
                        </div>
                        <div class="col span_4_of_12 footer__contact-us">
                            <button onClick={() => setContactUsForm(true)} class="button--primary modal-contact-link_footer">Contact Us</button>
                        </div>

                    </div>
                </div>

                <div class="disclosure">
                    <div class="container">
                        <p>&nbsp;</p>
                        <p><Link to="/glossary" aria-label="Glossary" class="disclosure_link_mod1">Glossary</Link></p>
                        <p><Link to="/sitemap" aria-label="Site Map" class="disclosure_link_mod1">Site Map</Link></p>
                        <p><b class="header">Disclosure</b></p>

                        <p>For current performance information click here:

                            <Link to="/glv?performance">GLV</Link>&nbsp;
                            <Link to="/glq?performance">GLQ</Link>&nbsp;
                            <Link to="/glo?performance">GLO</Link>&nbsp;
                            {/* <Link to="/long-short?performance">Clough Global Long/Short Fund</Link> */}

                        </p>

                        <p>For current holding information click here:

                            <Link to="/glv?holdings">GLV</Link>&nbsp;
                            <Link to="/glq?holdings">GLQ</Link>&nbsp;
                            <Link to="/glo?holdings">GLO</Link>&nbsp;
                            {/* <Link to="/long-short?holdings">Clough Global Long/Short Fund</Link> */}

                        </p>

                        <p>Additional information regarding the risks of this investment is available <Link to="../risks">here</Link>.</p>

                        <p><b>An investor should consider investment objectives, risks, charges and expenses carefully before investing. There are risks associated with investing, including possible loss of principal. From this website, for the Clough Global Dividend and Income Fund, Clough Global Equity Fund and Clough Global Opportunities Fund (the “Clough Global Closed-End Funds”) download an 
                        <a href={resources && resources['annual_report']?.length > 0 ? `${process.env.REACT_APP_API_LINK}download_resource/?id=${resources['annual_report'][0]?.id}&ticker=${resources['annual_report'][0]?.ticker}` : ''} target="_blank" aria-label="Click here to read the Annual Report">Annual Report</a> or &nbsp;
                        <a href={resources && resources['semi_annual_report']?.length > 0 ? `${process.env.REACT_APP_API_LINK}download_resource/?id=${resources['semi_annual_report'][0]?.id}&ticker=${resources['semi_annual_report'][0]?.ticker}` : ''} target="_blank" aria-label="Click here to read the Semi-Annual report">Semi-Annual Report</a>.
                        {/* <a href={resources && resources['prospectus']?.length > 0 ? `${process.env.REACT_APP_API_LINK}download_resource/?id=${resources['prospectus'][0]?.id}&ticker=${resources['prospectus'][0]?.ticker}` : ''} target="_blank" aria-label="Click here to read the Prospectus">Prospectus</a> which contains this and other information.  */}
                        &nbsp;Read them carefully before investing.</b></p>

                        <p>Investing involves risks, including loss of principal. A fund’s use of derivatives (which may include forward foreign currency contracts, futures, participation notes, and swaps) may reduce a fund’s returns and/or increase the volatility of a fund’s net asset value. Foreign investing involves special risks such as currency fluctuations and political uncertainty.</p>
                        <p>The Clough Global Dividend and Income Fund, Clough Global Equity Fund and Clough Global Opportunities Fund are closed-end funds and closed-end funds do not continuously issue shares for sale as open-end mutual funds do. Since the initial public offering, the funds now trade in the secondary market. Investors wishing to buy or sell shares need to place orders through an intermediary or broker. The share price of a closed-end fund is based on the market's value.</p>
                        <div class="spacer" />

                    </div>
                </div>

            </footer>
            {contactUsForm &&
                <ContactUs
                    setClosehandler={setContactUsForm} />
            }
            {scrollVisible > 100 &&
                <ul id="tinyNav">
                    <li id="tinyNav_backtotop" cont="Back to Top" onClick={() => backToTop()}>
                        <img src={arrowRight} width="30" height="30" tabindex="0" alt="Back to Top" />
                    </li>
                </ul>
            }
        </>
    );
}

export default Footer;
