import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ContactUs from "../modal-components/ContactUs";
import capitalLogo from '../assets/images/clough-capital-logo.svg';
import './style.css'

function Header() {
  const navigate = useNavigate();
  const [fundAction, setFundAction] = useState(false);
  const [resourceAction, setResourceAction] = useState(false);
  const [mobileNavActive, setMobileNavActive] = useState(false);
  const [contactUsForm, setContactUsForm] = useState(false);

  const redirectTo = (path) => {
    navigate(path)
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
  return (
    <>
      <header id="mainHeader">
        {/* <!--  MAIN NAV  --> */}

        <div class="full-width" id="navamongus">

          <div class="meta-nav__wrapper">
            <div class="container">
              <ul class="meta-nav">
                <li class="meta-nav__item">
                  <div onClick={() => redirectTo('/')} class="meta-nav__link">Home</div>
                </li>
                <li class="meta-nav__item"><a onClick={() => setContactUsForm(true)} class="button meta-nav__link modal-contact-link">Contact Us</a></li>
              </ul>
            </div>
          </div>

          {/* <!--  NAV  --> */}

          <div class="nav_container">

            <div onClick={() => redirectTo('/')} class="site-logo">
              <img src={capitalLogo} width="240px" height="60px" alt="Clough Global Partners LP" />
            </div>

            <ul id="main-nav">
              <li class="main-nav-item" onMouseEnter={() => setFundAction(true)} onMouseLeave={() => setFundAction(false)}>
                <div className="main-nav-dropdown_title list">The Funds</div>
                <div className={`nav-dropdown-global ${fundAction ? 'active' : ''}`}>
                  <div className="product-list">
                    <ul className="header">CLOSED-END FUNDS</ul>
                    <li className="drop-list"><div onClick={() => redirectTo("/glv")}>GLV - Clough Global Dividend and Income Fund</div></li>
                    <li className="drop-list"><div onClick={() => redirectTo("/glq")}>GLQ - Clough Global Equity Fund</div></li>
                    <li className="drop-list"><div onClick={() => redirectTo("/glo")}>GLO - Clough Global Opportunities Fund</div></li>
                  </div>
                  {/* <div className="splitter product-list"></div>
                  <div className="product-list">
                    <ul className="header">OPEN-END FUNDS</ul>
                    <li className="drop-list"><div onClick={() => redirectTo("/long-short")}>Clough Global Long/Short Fund</div></li>
                  </div> */}
                </div>
              </li>
              <li class="main-nav-item">
                <div className="main-nav-dropdown_title" onClick={() => redirectTo("/the-advisor")}>The Advisor</div>
              </li>
              <li class="main-nav-item" onMouseEnter={() => setResourceAction(true)} onMouseLeave={() => setResourceAction(false)}>
                <div className="main-nav-dropdown_title list">Resources</div>
                <div className={`nav-dropdown-global ${resourceAction ? 'active' : ''}`}>
                  <div className="product-list">
                    <ul className="header">CLOSED-END FUND RESOURCES</ul>
                    <li className="drop-list"><div onClick={() => redirectTo("/resources/glv")}>GLV - Clough Global Dividend and Income Fund</div></li>
                    <li className="drop-list"><div onClick={() => redirectTo("/resources/glq")}>GLQ - Clough Global Equity Fund</div></li>
                    <li className="drop-list"><div onClick={() => redirectTo("/resources/glo")}>GLO - Clough Global Opportunities Fund</div></li>
                  </div>
                  {/* <div className="splitter product-list"></div>
                  <div className="product-list">
                    <ul className="header">OPEN-END FUND RESOURCES</ul>
                    <li className="drop-list"><div onClick={() => redirectTo("/resources/long-short")}>Clough Global Long/Short Fund</div></li>
                  </div> */}
                </div>
              </li>

            </ul>

          </div>

          {/* <div id="mobileNavButton" aria-label="Menu" tabindex="0"></div> */}
          <div id="mobileNavHamburgerButton" onClick={() => setMobileNavActive(true)}></div>
          <div className={`mobileNav__bg ${mobileNavActive ? 'active' : ''}`} onClick={() => setMobileNavActive(false)}></div>
          <div className={`mobileNavMenu ${mobileNavActive ? 'active' : ''}`}>

            <div class="logoCloseMobile">
              <img src="../assets/images/btn-close.svg" width="20" height="20" className="logoCloseMobile__x" onClick={() => setMobileNavActive(false)} />
            </div>

            <div class="mainNavMobile">
              <div className="mainNav__mobile" onClick={() => { setResourceAction(false); setFundAction(!fundAction) }}>
                <div className="mainNavMobile__title">The Funds</div>
                <div className={`list-icon ${fundAction ? 'active' : ''}`}></div>
              </div>
              <div className={`mobile-nav-droplist ${fundAction ? 'active' : ''}`}>
                <div className="product-list">
                  <ul className="header">CLOSED-END FUNDS</ul>
                  <li className="drop-list" onClick={() => setMobileNavActive(false)}><a onClick={() => redirectTo('/glv')}>GLV - Clough Global Dividend and Income Fund</a></li>
                  <li className="drop-list" onClick={() => setMobileNavActive(false)}><a onClick={() => redirectTo('/glq')}>GLQ - Clough Global Equity Fund</a></li>
                  <li className="drop-list" onClick={() => setMobileNavActive(false)}><a onClick={() => redirectTo('/glo')}>GLO - Clough Global Opportunities Fund</a></li>
                </div>
                {/* <div className="product-list">
                  <ul className="header" style={{ marginTop: "10px" }}>OPEN-END FUNDS</ul>
                  <li className="drop-list" onClick={() => setMobileNavActive(false)}><a onClick={() => redirectTo("/long-short")}>Clough Global Long/Short Fund</a></li>
                </div> */}
              </div>
              <div className="mainNav__mobile">
                <div class="mainNavMobile__title" onClick={() => setMobileNavActive(false)}><a onClick={() => redirectTo("/the-advisor")}>The Advisor</a></div>
              </div>
              <div className="mainNav__mobile" onClick={() => { setFundAction(false); setResourceAction(!resourceAction) }}>
                <div class="mainNavMobile__title">Resources</div>
                <div className={`list-icon ${resourceAction ? 'active' : ''}`}></div>
              </div>
              <div className={`mobile-nav-droplist ${resourceAction ? 'active' : ''}`}>
                <div className="product-list">
                  <ul className="header">CLOSED-END FUNDS</ul>
                  <li className="drop-list" onClick={() => setMobileNavActive(false)}><a onClick={() => redirectTo("/resources/glv")}>GLV - Clough Global Dividend and Income Fund</a></li>
                  <li className="drop-list" onClick={() => setMobileNavActive(false)}><a onClick={() => redirectTo("/resources/glq")}>GLQ - Clough Global Equity Fund</a></li>
                  <li className="drop-list" onClick={() => setMobileNavActive(false)}><a onClick={() => redirectTo("/resources/glo")}>GLO - Clough Global Opportunities Fund</a></li>
                </div>
                {/* <div className="product-list">
                  <ul className="header" style={{ marginTop: "10px" }}>OPEN-END FUNDS</ul>
                  <li className="drop-list" onClick={() => setMobileNavActive(false)}><a onClick={() => redirectTo("/resources/long-short")}>Clough Global Long/Short Fund</a></li>
                </div> */}
              </div>
              <div className="mainNav__mobile">
                <div class="mainNavMobile__title modal-contact-link" onClick={() => { setContactUsForm(true); setMobileNavActive(false) }}>Contact</div>
              </div>

            </div>

          </div>
        </div>
        {/* </div> */}
      </header>
      {contactUsForm &&
        <ContactUs
          setClosehandler={setContactUsForm} />
      }
    </>
  );
}

export default Header;
