import React, { useEffect, useState, useRef } from "react";
import { DOCUMENT_TYPE } from '../../utils/queries'
import { useLazyQuery } from "@apollo/client";
import Footer from "../../components/footer";
import Header from "../../components/header";
import GLVResource from "./GLVResource";
import GLQResource from "./GLQResource";
import GLOResource from "./GLOResource";
import LongShortResources from "./LongShortResources";
import UseIsInViewport from '../../utils/useInViewPort'
import { useLocation, useNavigate } from "react-router-dom";
import VideoThumbnail from "../Home/components/VideoThumbnail";

function Resources() {
    const EDUCATION = 'education'
    const REGULATORY = 'regulatory'
    const TAX_INFORMATION = 'tax_information'
    const FUND_NEWS = 'fund_news'
    const location = useLocation()
    const navigate = useNavigate()
    const [activeTab, setActiveTab] = useState(null);
    const [activeResource, setActiveResource] = useState(null);
    const [scrollActive, setScrollActive] = useState(false)
    const [activeSection, setActiveSection] = useState(EDUCATION)
    const [scrollVisible, setScrollVisible] = useState(false);
    const [dropdownActive, setDropdownActive] = useState(false)
    const ref1 = useRef(null);
    const ref2 = useRef(null);
    const ref3 = useRef(null);
    const ref4 = useRef(null);

    const isInViewport1 = UseIsInViewport(ref1);
    const isInViewport2 = UseIsInViewport(ref2);
    const isInViewport3 = UseIsInViewport(ref3);
    const isInViewport4 = UseIsInViewport(ref4);

    const [
        documentType,
        { data: documentData, loading: documentLoading, error: documentError },
    ] = useLazyQuery(DOCUMENT_TYPE);

    // useEffect(() => {
    //     documentType({ variables: { ticker: 'CLOUGH' } });
    // }, [])

    useEffect(() => {
        window.addEventListener('scroll', () => setScrollVisible(window.pageYOffset))
    }, [])

    useEffect(() => {
        if (location?.pathname) {
            let params = location?.pathname?.split('/')
            documentType({ variables: { ticker: params[2] ? params[2]?.toUpperCase() : 'CLOUGH' } });
            setActiveResource(params[2])
        }
    }, [location])

    useEffect(() => {
        if (location?.search) {
            let activeId = location.search.split('?')?.length > 0 ? location.search.split('?')[1] : null
            handleClickScroll(activeId)
            if (location.search.split('?')?.length > 1) {
                let activeTab = location.search.split('?')[2]
                setActiveTab(activeTab)
                setTimeout(() => {
                    setActiveTab(null)
                }, [1000])
            }
            // if (activeResource) {
            //     navigate(`/${activeResource}`)
            // }
        }
    }, [activeResource, location])

    useEffect(() => {
        if (!scrollActive) {
            if (scrollVisible < 10) {
                setActiveSection(EDUCATION)
            } else if (isInViewport2) {
                setActiveSection(REGULATORY)
            } else if (isInViewport3) {
                setActiveSection(TAX_INFORMATION)
            } else if (isInViewport4) {
                setActiveSection(FUND_NEWS)
            } else if (isInViewport1) {
                setActiveSection(EDUCATION)
            }
        }
    }, [scrollActive, scrollVisible, isInViewport1, isInViewport2, isInViewport3, isInViewport4])

    const handleClickScroll = async (id) => {
        await setScrollActive(true)
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ block: 'center', behavior: 'smooth' });
            setActiveSection(id)
        }
        setDropdownActive(false)
        setTimeout(() => {
            setScrollActive(false)
        }, 1000)
    };

    const getDocumentType = (document) => {
        let getUniqueTypes = document?.length ? new Set(document.map(data => data.type)) : [];

        return document?.length && Array.from(new Set(document.map(data => data.type)))?.map(t => ({ type: t?.replaceAll('-', '_') }));
        // document?.map((data, idx) => {
        //     let obj = {
        //         type: data.type,
        //         friendlyName: data.friendlyName,
        //     }
        //     tmpArr.push(obj)
        // })
        // return tmpArr
    }

    return (
        <>
            <Header />
            <main id="mainContent">
                <div class="main_content">
                    <div class={`hero-short hero-short__img-3 video-element`}>
                        <div className="video-thumbnail-fund">
                            <VideoThumbnail />
                            <div class="container">

                                <div class="row ">
                                    <div class="col span_1_of_1">
                                        <h2 class="hero-short__title">
                                            {activeResource == 'glv' ? 'Clough Global Dividend and Income Fund' :
                                                activeResource == 'glq' ? 'Clough Global Equity Fund' :
                                                    activeResource == 'glo' ? 'Clough Global Opportunities Fund' :
                                                        activeResource == 'long-short' ? 'Clough Global Long/Short Fund' : ''
                                            }
                                        </h2>
                                        <h1 class="hero-short__ticker">
                                            {activeResource == 'glv' ? 'GLV' :
                                                activeResource == 'glq' ? 'GLQ' :
                                                    activeResource == 'glo' ? 'GLO' :
                                                        activeResource == 'long-short' ? 'CLOIX, CLOAX, CLOVX, CLOCX' : ''}
                                        </h1>

                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class={`subnav__wrapper ${scrollVisible > 300 ? 'sticky' : ''}`}>
                            <div class="container">

                                <ul class="subnav">
                                    <li class={`subnav__item ${activeSection == EDUCATION ? 'active' : ''}`}>
                                        <a class="subnav__link" onClick={() => handleClickScroll(EDUCATION)}>Education</a>
                                    </li>
                                    <li class={`subnav__item ${activeSection == REGULATORY ? 'active' : ''}`}>
                                        <a class="subnav__link" onClick={() => handleClickScroll(REGULATORY)}>Regulatory</a>
                                    </li>
                                    {activeResource !== 'long-short' ?
                                        <>
                                            <li class={`subnav__item ${activeSection == TAX_INFORMATION ? 'active' : ''}`}>
                                                <a class="subnav__link" onClick={() => handleClickScroll(TAX_INFORMATION)}>Tax Information</a>
                                            </li>
                                            <li class={`subnav__item ${activeSection == FUND_NEWS ? 'active' : ''}`}>
                                                <a class="subnav__link" onClick={() => handleClickScroll(FUND_NEWS)}>Fund News</a>
                                            </li>
                                        </> :
                                        ''
                                    }
                                </ul>
                                <div className="subnav_mobile-dropdown">
                                    <div class={`styledSelect ${dropdownActive ? 'active' : ''}`} aria-role="select" onClick={() => setDropdownActive(!dropdownActive)}>
                                        {activeSection ? activeSection?.replace(/_/g, ' ') : 'SELECT A Sub Section'}
                                    </div>
                                    {dropdownActive &&
                                        <ul class="options subnavDropdown" style={{}}>
                                            <li onClick={() => handleClickScroll(EDUCATION)}>Education</li>
                                            <li onClick={() => handleClickScroll(REGULATORY)}>Regulatory</li>
                                            {activeResource !== 'long-short' ?
                                                <>
                                                    <li onClick={() => handleClickScroll(TAX_INFORMATION)}>Tax Information</li>
                                                    <li onClick={() => handleClickScroll(FUND_NEWS)}>Fund News</li>
                                                </> : ''
                                            }
                                        </ul>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    {activeResource == 'glv' ?
                        <GLVResource ref1={ref1} ref2={ref2} ref3={ref3} ref4={ref4} activeTab={activeTab} documentData={getDocumentType(documentData?.documentType) || []} />
                        : activeResource == 'glq' ?
                            <GLQResource ref1={ref1} ref2={ref2} ref3={ref3} ref4={ref4} activeTab={activeTab} documentData={getDocumentType(documentData?.documentType) || []} />
                            : activeResource == 'glo' ?
                                <GLOResource ref1={ref1} ref2={ref2} ref3={ref3} ref4={ref4} activeTab={activeTab} documentData={getDocumentType(documentData?.documentType) || []} />
                                : activeResource == 'long-short' ?
                                    <LongShortResources ref1={ref1} ref2={ref2} documentData={getDocumentType(documentData?.documentType) || []} />
                                    : ''
                        // navigate('/')
                    }
                </div>
                <div class="spacer" />
            </main>
            <Footer />
        </>
    );
}

export default Resources;
