export const getUniqueFunds = (data) => {
    let sortArray = [...new Set(data?.flat().sort((a, b) => b['createdAt']?.toString().localeCompare(a['createdAt']?.toString())).map(item => item))]
    const uniqueIds = [];
    const uniqueArray = sortArray.filter(element => {
        const isDuplicate = uniqueIds.includes(element['friendlyName']);
        if (!isDuplicate) {
            uniqueIds.push(element['friendlyName']);
            return true;
        }
        return false;
    });
    return uniqueArray
}

export const getQuarterSite = (data, friendlyName) => {
    let records = {}
    let type = 'banner'
    if (data?.resources?.completeFundData?.length > 0) {
        let document = data?.resources?.completeFundData?.filter((x) => x.type == type)
        let fundResource = document?.length > 0 ? document[0]?.funds?.filter((x) => x.friendlyName.includes(friendlyName)) : []
        if (fundResource?.length > 0) {
            records = {
                id: fundResource[0]?.id,
                friendlyName: fundResource[0]?.friendlyName,
                ticker: 'CLOUGH'
            }
        }
    }
    return records
}

export function performanceFormat(value) {
    return typeof(value) === 'number' && !isNaN(value) ? (value*100).toFixed(2)+'%' : '-';
}
