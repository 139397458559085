import React from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const colors = ['#0A85C7', '#A7A9AC', '#D7102C'];


function ChartComponents(props) {

  const { hedgeFund, closedEndFund, etf } = props

  const data = [
    {
      name: 'Page A',
      uv: hedgeFund,
    },
    {
      name: 'Page B',
      uv: closedEndFund,
    },
    {
      name: 'Page C',
      uv: etf,
    },
  ];

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart width={150} height={40} data={data} barSize={100}>
        <CartesianGrid vertical={false} />
        <YAxis domain={[0, 800]} tickCount={7} />
        <Bar dataKey="uv" fill="#8884d8" label={{ position: 'top' }}>
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={colors[index % 20]} />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
}

export default ChartComponents;
