import { useState, useEffect, useMemo } from "react";

function UseIsInViewport(ref) {
  const [isIntersecting, setIsIntersecting] = useState(false);

  // const observer = useMemo(
  //   () =>
  //     new IntersectionObserver(([entry]) => 
  //       setIsIntersecting(entry.isIntersecting),
  //     ),
  //   [],
  // );

  const observer = new IntersectionObserver(
    ([entry]) => {
      setIsIntersecting(entry.isIntersecting);
    },
    { rootMargin: "-300px" }
  );

  const onScroll = () => {
    if (!ref.current) {
      return
    }
    let offset = 0
    let top = ref.current.getBoundingClientRect().top
    // let isInViewport = top + offset >= 0 && top - offset <= window.innerHeight
    let isInViewport = top + offset >= 0 
    if (isInViewport) {
      observer.observe(ref.current);

      return () => {
        observer.disconnect();
      };
    }
  }

  useEffect(() => {
    document.addEventListener('scroll', onScroll, true)
    return () => document.removeEventListener('scroll', onScroll, true)
  })

  return isIntersecting;
}

export default UseIsInViewport;