import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade, Autoplay, Pagination, Navigation } from 'swiper';
import Banner1 from '../../../assets/images/clough_q422_commentary_banner_1.jpg'
import Banner2 from '../../../assets/images/clough_q422_commentary_banner_2.jpg'
import Banner3 from '../../../assets/images/clough_q422_commentary_banner_3.jpg'

import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";

import "./style.css";
import VideoThumbnail from "./VideoThumbnail";

export default function SliderShow(props) {
  const { quarterSite } = props
  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      return '<span class="' + className + '">' + (index + 1) + "</span>";
    },
  };

  const slderList = [
    {
      backgroundImage: Banner1,
      title: `<strong>Market Commentary: What Caused the 40-year Decline in Interest Rates and Inflation? </strong>`,
      // url: quarterSite?.id ? `${process.env.REACT_APP_API_LINK}download_resource/?id=${quarterSite?.id}&ticker=${quarterSite?.ticker}` : '',
      // redirectTitle: quarterSite?.friendlyName ? `${quarterSite?.friendlyName} &gt;` : '',
      color: '#3c4d75'
    },
    {
      backgroundImage: Banner2,
      title: `<strong>2023 Healthcare Spending: Medical Technology &amp; Biopharmaceutical Companies </strong>`,
      // url: quarterSite?.id ? `${process.env.REACT_APP_API_LINK}download_resource/?id=${quarterSite?.id}&ticker=${quarterSite?.ticker}` : '',
      // redirectTitle: quarterSite?.friendlyName ? `${quarterSite?.friendlyName} &gt;` : '',
      color: '#1049ab'
    },
    {
      backgroundImage: Banner3,
      title: `<strong>Supply and Demand: <br /> Energy Supply Constraints</strong>`,
      // url: quarterSite?.id ? `${process.env.REACT_APP_API_LINK}download_resource/?id=${quarterSite?.id}&ticker=${quarterSite?.ticker}` : '',
      // redirectTitle: quarterSite?.friendlyName ? `${quarterSite?.friendlyName} &gt;` : '',
      color: '#876e3b'
    },
  ]
  return (
    <>
      <Swiper
        pagination={pagination}
        effect={"fade"}
        className="header-slideShow"
        autoplay={{
          delay: 4000,
          disableOnInteraction: false,
        }}
        navigation={true}
        modules={[EffectFade, Autoplay, Pagination, Navigation]}
      >
        {slderList?.map((item, idx) => {
          return (
            <SwiperSlide key={idx}>
              <div class="hero-slideshow" style={{ opacity: "1", backgroundImage: `url(${item?.backgroundImage})` }}>
                <div class="hero-slideshow__content" data-key="body" style={{ cursor: "default", opacity: "1" }}>
                  {/* <div class="hero_home_bg"></div> */}
                  <div class="col span_4_of_10 mobile_hide">&nbsp;</div>
                  <div class="col span_5_of_10 mobile_wrapper">
                    {/* <div class="hero_content_wrapper">
                      <div class="hero_content_position">
                        <h1 class="hero__title--static" style={{ opacity: "1", color: "#082331", fontSize: "3rem", fontStyle: "normal", fontStyle: "bold" }}
                          dangerouslySetInnerHTML={{ __html: item?.title }} />
                        <br />
                        {item?.redirectTitle?.trim() !== '' &&
                          <a href={item.url} target="_blank" style={{ opacity: "1", color: `${item?.color}`, fontSize: "2.5rem", fontWeight: "500" }}
                            dangerouslySetInnerHTML={{ __html: item?.redirectTitle }} />
                        }
                      </div>
                    </div> */}
                    </div>
                    <VideoThumbnail />
                    </div>
              </div>
            </SwiperSlide>
          )
        })}
      </Swiper>
    </>
  );
}
