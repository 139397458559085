import React, { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import moment from "moment";
import { RESOURCES_QUERY } from '../../utils/queries'
import { Link } from "react-router-dom";
import downloadIcon from '../../assets/images/download_icon.svg'
import docLinkIcon from '../../assets/images/doclink_icon.svg'

function LongShortResources(props) {
    const { ref1, ref2, ref3, ref4, documentData } = props

    const [currentList, setCurrentList] = useState([]);
    const [regulatoryList, setRegulatoryList] = useState([]);

    const [
        fetchResources,
        { data: resourceData, loading: resourceLoading, error: resourceError },
    ] = useLazyQuery(RESOURCES_QUERY);

    useEffect(() => {
        fetchResources({ variables: { ticker: 'GLS', documentType: documentData, documentTypes: documentData } });
    }, [documentData])

    const getUniqueFunds = (data) => {
        let sortArray = [...new Set(data?.flat().sort((a, b) => b['createdAt']?.toString().localeCompare(a['createdAt']?.toString())).map(item => item))]
        const uniqueIds = [];
        const uniqueArray = sortArray.filter(element => {
            const isDuplicate = element['friendlyName'] !== "Clough Global Allocation Fund Announces Tax Information" && uniqueIds.includes(element['friendlyName']);
            if (!isDuplicate) {
                uniqueIds.push(element['friendlyName']);

                return true;
            }
            return false;
        });
        return uniqueArray
    }

    useEffect(() => {
        let completeFund = resourceData?.resources?.completeFundData
        let currentUpdates = []
        let regulatoryUpdates = []
        completeFund?.length > 0 && completeFund?.map((data) => {
            if ((data?.type == 'monthly_factsheet' || data?.type == 'monthly-factsheet') && data?.funds?.length > 0) {
                currentUpdates?.push(data?.funds)
            }
            if ((data?.type == 'semi_annual_report' || data?.type == 'semi-annual-report') && data?.funds?.length > 0) {
                regulatoryUpdates.push(data?.funds)
            }
            if ((data?.type == 'annual_report' || data?.type == 'annual-report') && data?.funds?.length > 0) {
                regulatoryUpdates.push(data?.funds)
            }
            if ((data?.type == 'regulatory') && data?.funds?.length > 0) {
                regulatoryUpdates.push(data?.funds)
            }
            if ((data?.type == 'prospectus_supplement' || data?.type == 'prospectus-supplement') && data?.funds?.length > 0) {
                regulatoryUpdates.push(data?.funds)
            }
            if ((data?.type == 'prospectus') && data?.funds?.length > 0) {
                regulatoryUpdates.push(data?.funds)
            }
        })
        setCurrentList(getUniqueFunds(currentUpdates))
        setRegulatoryList(getUniqueFunds(regulatoryUpdates))
    }, [resourceData])

    return (
        <>

            <div class="container inner-content video-element">

                <div id="education" class="waypoint__subnav" ref={ref1}></div>
                <h3>RESOURCES</h3>
                <br /><br />

                <h3 class="mod2 underline">Education</h3>


                <table class="table--mod-1 table_alt1">
                    <tbody><tr>
                        <td><b>Document</b></td>
                        <td><b>Date</b></td>
                        <td><b>Download</b></td>
                    </tr>
                        {currentList?.length > 0 && currentList?.map((data, idx) => {
                            return (
                                <tr key={idx}>
                                    <td>{data?.friendlyName}</td>
                                    <td>{data.createdAt ? moment(data.createdAt).format("MM/DD/yyyy") : '-'}</td>
                                    <td>
                                        <a href={`${process.env.REACT_APP_API_LINK}download_resource/?id=${data?.id}&ticker=${data?.ticker}`} target="_blank" style={{}}>
                                            <img src={downloadIcon} width="22" height="22" alt="Download Section 19a PDF" title="Download" />
                                        </a>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody></table>

                <div className="spacer" />



                <h3 class="mod2 underline">Regulatory</h3>
                
                <div id="regulatory" class="waypoint__subnav" ref={ref2}></div>

                <div class="regulatory-wrapper">
                    <div id="regulatoryTableWraper" class="tableHorizontalScroll" style={{ overflowX: "hidden" }}>
                        <table class="table--mod-1 table_alt1">
                            <tbody><tr>
                                <td><b>Document</b></td>
                                <td><b>Date</b></td>
                                <td><b>Download</b></td>
                            </tr>
                                {regulatoryList?.length > 0 && regulatoryList?.map((data, idx) => {
                                    return (
                                        <tr key={idx}>
                                            <td>{data?.friendlyName}</td>
                                            <td>{data.createdAt ? moment(data.createdAt).format("MM/DD/yyyy") : '-'}</td>
                                            <td>
                                                <a href={`${process.env.REACT_APP_API_LINK}download_resource/?id=${data?.id}&ticker=${data?.ticker}`} target="_blank" style={{}}>
                                                    <img src={downloadIcon} width="22" height="22" alt="Download Section 19a PDF" title="Download" />
                                                </a>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                    <div id="regulatoryTableIndicator" class="tableOverflowIndicatorMobile">← Swipe to see additional data →</div>
                </div>
                <div className="spacer" />

            </div>


            <div className="spacer" />

        </>
    );
}

export default LongShortResources;