import { gql } from "@apollo/client";

export let RESOURCES_QUERY = gql`
query fundNews($ticker: String!, $documentTypes: [DocumentInputType]!){
	resources
	{        
        completeFundData(ticker: $ticker, documentTypes: $documentTypes){
            type
            funds {
                id
                friendlyName
                fileName
                createdAt
                documentType
                locationId
                ticker
            }
        }
	}
	
}`;
export let DAILY_NAV = gql`
query($ticker: String!){
    fund(ticker: $ticker) {
        dailyData {
            asOfDate
            ticker
            CUSIP
            inceptionDate
            NAV
            NAVChange
            marketPrice
            premiumDiscount
            navTicker
            monthlyDistributionRate
            distributionRate
        }
    }
}
`;
export let FUND_QUERY = gql`
query($ticker: String!){
    fund(ticker: $ticker) {
        dailyData {
            asOfDate
            ticker
            CUSIP
            inceptionDate
            NAV
            NAVChange
            marketPrice
            premiumDiscount
            navTicker
            monthlyDistributionRate
            distributionRate
        }
        top10Holdings {
            asOfDate
            description
            ticker
            totalNetAssetPercent
            type
        }
        fullHoldings {
            asOfDate
            description
            ticker
            totalNetAssetPercent
            type
        }
        quarterlyPerformance {
            asOfDate
            oneYearMarket
            threeYearMarket
            fiveYearMarket
            tenYearMarket
            s1Market
            oneYearNav
            threeYearNav
            fiveYearNav
            tenYearNav
            s1Nav
        }
        monthlyPerformance {
            asOfDate
            oneMonthMarket
            threeMonthMarket
            ytdMarket
            siMarket
            oneMonthNav
            threeMonthNav
            ytdNav
            siNav
        }
        distribution {
            exDate
            recordDate
            payableDate
            amount
            distributionType
            sec19ANotice {
                id
                friendlyName
                fileName
                createdAt
                documentType
                locationId
                ticker
            }
        }
    }
}`;

export let DOWNLOAD_FILE = gql`
query downloadResource ($id: String!, $ticker: String!) {
    downloadResource(id: $id, ticker: $ticker)
}
`;
export let RUN_CELERY_JOB = gql`
query runCeleryJob ($taskName: String!) {
    runCeleryJob(taskName: $taskName)
}
`;
export let DOCUMENT_TYPE = gql`
query documentType ($ticker: String!) {
    documentType(ticker: $ticker) {
        id
        type
        description
        friendlyName
        ticker
        documentBaseDirFromFund 
    }
}
`;
export let CONTACT_MUTATION = gql`
 mutation sendContactUsMail (
    $body: String
    $subject: String
    $emails: [String]
    $ticker: String
) {
  sendContactUsMail(
    emailBody: $body
    emails: $emails
    emailSubject: $subject
    ticker: $ticker
  ) {
    ok
  }
}
`;
