import React, { useEffect, useState, useRef } from "react";
import { DOCUMENT_TYPE, RESOURCES_QUERY } from '../../utils/queries'
import { useQuery, useLazyQuery } from "@apollo/client";
import Footer from "../../components/footer";
import Header from "../../components/header";
import GLVFund from "./GLVFund";
import GLQFund from "./GLQFund";
import GLOFund from "./GLOFund";
import LongShortFund from "./LongShortFund";
import UseIsInViewport from '../../utils/useInViewPort'
import { getQuarterSite } from '../../utils/helper'
import { useLocation, useNavigate } from "react-router-dom";

import './style.css'
import VideoThumbnail from "../Home/components/VideoThumbnail";

function Fund() {
    const FUND_DETAILS = 'fund_details'
    const PERFORMANCE = 'performance'
    const HOLDINGS = 'holdings'
    const DISTRIBUTION = 'distributions'
    const RESOURCES = 'resources'
    const location = useLocation()
    const navigate = useNavigate()
    const [activeFund, setActiveFund] = useState(null);
    const [activeTab, setActiveTab] = useState(null);
    const [scrollActive, setScrollActive] = useState(false)
    const [scrollVisible, setScrollVisible] = useState(false);
    const [dropdownActive, setDropdownActive] = useState(false)
    const [activeSection, setActiveSection] = useState(FUND_DETAILS)
    const ref1 = useRef(null);
    const ref2 = useRef(null);
    const ref3 = useRef(null);
    const ref4 = useRef(null);
    const ref5 = useRef(null);

    const isInViewport1 = UseIsInViewport(ref1);
    const isInViewport2 = UseIsInViewport(ref2);
    const isInViewport3 = UseIsInViewport(ref3);
    const isInViewport4 = UseIsInViewport(ref4);
    const isInViewport5 = UseIsInViewport(ref5);

    const [
        documentType,
        { data: documentData, loading: documentLoading, error: documentError },
    ] = useLazyQuery(DOCUMENT_TYPE);

    // const { data: cloughBannerData, loading: cloughBannerLoading, error: cloughBannerError } = useQuery(RESOURCES_QUERY, { variables: { ticker: 'CLOUGH', documentType: [{ type: 'banner' }], documentTypes: [{ type: 'banner' }] } });

    // useEffect(() => {
    //     documentType({ variables: { ticker: 'CLOUGH' } });
    // }, [])

    useEffect(() => {
        if (location?.pathname) {
            let params = location?.pathname?.split('/')
            documentType({ variables: { ticker: params[1] ? params[1]?.toUpperCase() : 'CLOUGH' } });
            setActiveFund(params[1])

        }
    }, [location])

    useEffect(() => {
        if (location?.search) {
            let activeId = location.search.split('?')?.length > 0 ? location.search.split('?')[1] : null
            handleClickScroll(activeId)
            if (location.search.split('?')?.length > 1) {
                let activeTab = location.search.split('?')[2]
                setActiveTab(activeTab)
                setTimeout(() => {
                    setActiveTab(null)
                }, [1000])
            }
            // if (activeFund) {
            //     navigate(`/${activeFund}`)
            // }
        }
    }, [activeFund, location])

    useEffect(() => {
        if (!scrollActive) {
            if (isInViewport2) {
                setActiveSection(PERFORMANCE)
            } else if (isInViewport3) {
                setActiveSection(HOLDINGS)
            } else if (isInViewport4) {
                setActiveSection(DISTRIBUTION)
            } else if (isInViewport5) {
                setActiveSection(RESOURCES)
            } else if (isInViewport1) {
                setActiveSection(FUND_DETAILS)
            }
        }
    }, [scrollActive, isInViewport1, isInViewport2, isInViewport3, isInViewport4, isInViewport5])

    useEffect(() => {
        window.addEventListener('scroll', () => setScrollVisible(window.pageYOffset))
    }, [])

    const handleClickScroll = async (id) => {
        await setScrollActive(true)
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ block: 'center', behavior: 'smooth' });
            setActiveSection(id)
        }
        setDropdownActive(false)
        setTimeout(() => {
            setScrollActive(false)
        }, 1000)
    };

    const getDocumentType = (document) => {
        let getUniqueTypes = document?.length ? new Set(document.map(data => data.type)) : [];

        return document?.length && Array.from(new Set(document.map(data => data.type)))?.map(t => ({ type: t?.replaceAll('-', '_') }));
        // document?.map((data, idx) => {
        //     let obj = {
        //         type: data.type,
        //         friendlyName: data.friendlyName,
        //     }
        //     tmpArr.push(obj)
        // })
        // return tmpArr
    }

    return (
        <>
            <Header />
            <main id="mainContent">
                <div class="main_content">
                    <div class={`hero-short callout-resize hero-short__img-2 single_fund video-thumbnail`}>
                        <div className="video-thumbnail-fund">
                            <VideoThumbnail />
                            <div class="container">

                                <div class="row ">
                                    <div class="col span_1_of_1 lg-full">
                                        <span class="hero-short__title">
                                            {
                                                activeFund == 'glv' ? 'Clough Global Dividend and Income Fund' :
                                                    activeFund == 'glq' ? 'Clough Global Equity Fund' :
                                                        activeFund == 'glo' ? 'Clough Global Opportunities Fund' :
                                                            activeFund == 'long-short' ? 'Clough Global Long/Short Fund' :
                                                                ''}
                                        </span>
                                        <h2 class="hero-short__ticker">
                                            {
                                                activeFund == 'glv' ? 'GLV' :
                                                    activeFund == 'glq' ? 'GLQ' :
                                                        activeFund == 'glo' ? 'GLO' :
                                                            ''}
                                        </h2>
                                        {activeFund == 'long-short' &&
                                            <h2 class="hero-short__ticker">CLOIX, CLOAX, CLOVX, CLOCX<a href="https://www3.financialtrans.com/tf/FANWeb?tx=Disclaim&amp;cz=80011021114200607" target="_blank" alt="Shareholder Login">Shareholder Login</a></h2>
                                        }
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class={`subnav__wrapper ${scrollVisible > 300 ? 'sticky' : ''}`}>
                            <div class="container">

                                <ul class="subnav">
                                    <li class={`subnav__item ${activeSection == FUND_DETAILS ? 'active' : ''}`}>
                                        <a class="subnav__link" onClick={() => handleClickScroll(FUND_DETAILS)}>Fund Details</a>
                                    </li>
                                    <li class={`subnav__item ${activeSection == PERFORMANCE ? 'active' : ''}`}>
                                        <a class="subnav__link" onClick={() => handleClickScroll(PERFORMANCE)}>Performance</a>
                                    </li>
                                    <li class={`subnav__item ${activeSection == HOLDINGS ? 'active' : ''}`}>
                                        <a class="subnav__link" onClick={() => handleClickScroll(HOLDINGS)}>Holdings</a>
                                    </li>
                                    <li class={`subnav__item ${activeSection == DISTRIBUTION ? 'active' : ''}`}>
                                        <a class="subnav__link" onClick={() => handleClickScroll(DISTRIBUTION)}>Distributions</a>
                                    </li>
                                    <li class={`subnav__item ${activeSection == RESOURCES ? 'active' : ''}`}>
                                        <a class="subnav__link" onClick={() => handleClickScroll(RESOURCES)}>Resources</a>
                                    </li>
                                </ul>

                                <div className="subnav_mobile-dropdown">
                                    <div class={`styledSelect ${dropdownActive ? 'active' : ''}`} aria-role="select" onClick={() => setDropdownActive(!dropdownActive)}>
                                        {activeSection ? activeSection?.replace(/_/g, ' ') : 'SELECT A Sub Section'}
                                    </div>
                                    {dropdownActive &&
                                        <ul class="options subnavDropdown" style={{}}>
                                            <li onClick={() => handleClickScroll(FUND_DETAILS)}>Fund Details</li>
                                            <li onClick={() => handleClickScroll(PERFORMANCE)}>Performance</li>
                                            <li onClick={() => handleClickScroll(HOLDINGS)}>Holdings</li>
                                            <li onClick={() => handleClickScroll(DISTRIBUTION)}>Distributions</li>
                                            <li onClick={() => handleClickScroll(RESOURCES)}>Resources</li>
                                        </ul>
                                    }
                                </div>
                            </div>
                            {/* {getQuarterSite(cloughBannerData, 'Clough Capital Partners Statement')?.id &&
                                <div class="callout" data-location="domain" data-type="is" data-id="domain-is-0" data-collected="true" data-startdate="06/03/2021" data-rangestartdate="Invalid date" data-title="Clough Capital Partners Statement on Prohibited Securities" data-url="documents/cloughglobal-is-20210603.pdf" data-abbrev="IS">
                                    <div class="container">
                                        <a href={getQuarterSite(cloughBannerData, 'Clough Capital Partners Statement')?.id ? `${process.env.REACT_APP_API_LINK}download_resource/?id=${getQuarterSite(cloughBannerData, 'Clough Capital Partners Statement')?.id}&ticker=${getQuarterSite(cloughBannerData, 'Clough Capital Partners Statement')?.ticker}` : ''} target="_blank">
                                            <span>{getQuarterSite(cloughBannerData, 'Clough Capital Partners Statement')?.friendlyName}</span>
                                        </a>
                                    </div>
                                </div>
                            } */}
                        </div>
                    </div>
                    {activeFund == 'glv' ?
                        <GLVFund ref1={ref1} ref2={ref2} ref3={ref3} ref4={ref4} ref5={ref5} activeTab={activeTab} documentData={getDocumentType(documentData?.documentType) || []} />
                        : activeFund == 'glq' ?
                            <GLQFund ref1={ref1} ref2={ref2} ref3={ref3} ref4={ref4} ref5={ref5} activeTab={activeTab} documentData={getDocumentType(documentData?.documentType) || []} />
                            : activeFund == 'glo' ?
                                <GLOFund ref1={ref1} ref2={ref2} ref3={ref3} ref4={ref4} ref5={ref5} activeTab={activeTab} documentData={getDocumentType(documentData?.documentType) || []} />
                                : activeFund == 'long-short' ?
                                    <LongShortFund ref1={ref1} ref2={ref2} ref3={ref3} ref4={ref4} ref5={ref5} activeTab={activeTab} documentData={getDocumentType(documentData?.documentType) || []} />
                                    : ''
                    }
                </div>
                <div class="spacer" />
            </main>
            <Footer />
        </>
    );
}

export default Fund;
