import React, { useEffect, useState, useRef } from "react";
import closeIcon from '../../assets/images/btn-close--modal.svg';
import { Link } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { CONTACT_MUTATION } from '../../utils/queries';
import './style.css';
import ReCAPTCHA from 'react-google-recaptcha';

function ContactUs(props) {
    const { setClosehandler } = props
    const CONTACT_INFO = 'Contact Info'
    const CONTACT_US = 'Contact US'
    const GLV = 'GLV'
    const GLQ = 'GLQ'
    const GLO = 'GLO'
    const GLONG_SHORT = 'GLS'
    const [contactAction, setContactAction] = useState(CONTACT_INFO);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phoneNum, setPhoneNum] = useState('');
    const [email, setEmail] = useState('');
    const [comment, setComment] = useState('');
    const [quarterlyLetterSignUp, setQuarterlyLetterSignUp] = useState(false);
    const [investmentProfessional, setInvestmentProfessional] = useState(false);
    const [activeTicker, setActiveTicker] = useState('');
    const [activeGLV, setActiveGLV] = useState(false);
    const [activeGLO, setActiveGLO] = useState(false);
    const [activeGLQ, setActiveGLQ] = useState(false);
    const [activeGLongShort, setActiveGLongShort] = useState(false);
    const [longShort, setLongShort] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [notify, setNotify] = useState(null);
    const [submitContact] = useMutation(CONTACT_MUTATION);
    const recaptcha = useRef();

    useEffect(() => {
        if (firstName?.trim() !== '' && lastName?.trim() !== '' && email?.trim() !== '' && (activeGLO || activeGLQ || activeGLV || activeGLongShort)) {
            setDisabled(false)
        } else {
            setDisabled(true)
        }
    }, [firstName, lastName, email, phoneNum, comment, activeGLO, activeGLQ, activeGLV, activeGLongShort])

    const getFundDescription = (ticker) => {
        let description = ''
        if (ticker == GLV) {
            description = 'Clough Global Dividend and Income Fund'
        } else if (ticker == GLQ) {
            description = 'Clough Global Equity Fund'
        } else if (ticker == GLO) {
            description = 'Clough Global Opportunities Fund'
        } else if (ticker == GLONG_SHORT) {
            description = 'Clough Global Long/Short Fund'
        }
        return description
    }

    const getActiveFundTicker = () => {
        let ticker = []
        if (activeGLV) { ticker.push('GLV') }
        if (activeGLO) { ticker.push('GLO') }
        if (activeGLQ) { ticker.push('GLQ') }
        if (activeGLongShort) { ticker.push('CLOIX, CLOAX, CLOVX, CLOCX') }
        return ticker.join(', ');
    }
    
    const sumbitForm = async () => {
        const captchaValue = recaptcha.current.getValue();
        if (!captchaValue) {
            setNotify("Please verify the reCAPTCHA!");
        }else{
            let body = `
            Hi,
            
            The following user has showed intrest in Clough Global.
        
            Name: ${firstName} ${lastName},
            Email: ${email},
            Phone: ${phoneNum},
            Message: ${comment},
            Investment Professional? : ${investmentProfessional ? 'Yes' : 'No'}
            Ticker : ${getActiveFundTicker()}
            
            Regards,
            Paralel Team
            `;
            // Quarterly Letter Signup? : ${quarterlyLetterSignUp ? 'Yes' : 'No'},
            let toEmail = "cloughclientinquiries@paralel.com";
            let returnDetails = await submitContact({
                variables: {
                    body, emails: [toEmail], subject: "Contact from website.", ticker: "CLOUGH"
                }
            });
            if (returnDetails?.data?.sendContactUsMail?.ok) {
                setNotify("Email Sent!");
                clearForm()
            } else {
                setNotify("Some Error Occured!")
            }
            setTimeout(() => {
                setNotify(null)
            }, 3000)
        }
    }
    const clearForm = () => {
        setFirstName('')
        setLastName('')
        setEmail('')
        setPhoneNum('')
        setComment('')
        setQuarterlyLetterSignUp(false)
        setInvestmentProfessional(false)
        setActiveGLV(false)
        setActiveGLO(false)
        setActiveGLQ(false)
        setActiveGLongShort(false)
        setLongShort(false)
        setNotify(null)
        recaptcha.current.reset();
    }
    let notifStyle = { textAlign: "center", fontSize: "22px", marginTop: "-30px" };
    return (
        <div id="modalOverlay">
            <div className="overlay--bg" onClick={() => setClosehandler(false)}></div>
            <div className="overlay--box contact-us">
                <div className="overlay--container">
                    <div className="overlay-close" onClick={() => setClosehandler(false)}>
                        <img src={closeIcon} width="25px" height="25px" alt="Close-Button" />
                    </div>
                    <div className="container-wrapper contact-us">
                        <div class="tabs">

                            <ul class="tabs__list">
                                <li class="tabs__list-item">
                                    <a class={`tabs__tab ${contactAction == CONTACT_INFO ? 'active' : ''}`} onClick={() => setContactAction(CONTACT_INFO)}>CONTACT INFO</a>
                                </li>
                                <li class="tabs__list-item">
                                    <a class={`tabs__tab ${contactAction == CONTACT_US ? 'active' : ''}`} onClick={() => setContactAction(CONTACT_US)}>CONTACT US</a>
                                </li>
                            </ul>
                            {contactAction == CONTACT_INFO &&
                                <div id="tab1__contact">

                                    <h3 class="mod1 underline">Closed-End Funds</h3><br />
                                    Clough Global Closed-End Funds<br />
                                    1700 Broadway, Suite 1850<br />
                                    Denver, CO 80290<br /><br />
                                    <a href="tel:18554256844">1.855.425.6844</a><br /><br />
                                    <a href="mailto:cloughclientinquiries@paralel.com">cloughclientinquiries@paralel.com</a><br />

                                    {/* <h3 class="mod1 underline">Open-End Funds</h3><br />
                                    Clough Global Long/Short Fund<br />
                                    P.O. Box 1920 <br />
                                    Denver, CO 80201 <br /><br />

                                    <a href="tel:18554256844">1.855.425.6844</a><br /><br /> */}
                                    {/* <a href="mailto:cloughclientinquiries@paralel.com">cloughclientinquiries@paralel.com</a><br /> */}

                                    <h3 class="mod1 underline">Advisor</h3><br />
                                    Clough Capital Partners, L.P.<br />
                                    53 State Street, 27th Floor<br />
                                    Boston MA 02109<br /><br />

                                    <a href="tel:6172043400">617.204.3400</a><br /><br />
                                    <a href="mailto:investorrelations@cloughcapital.com">investorrelations@cloughcapital.com</a><br />

                                </div>
                            }
                            {contactAction == CONTACT_US &&
                                <div id="tab2__contact">
                                    <br /><br />
                                    <div id="formsub" class="formSub">
                                        <div class='alert-error'>
                                            <strong>
                                                <div class='closeX'>×</div>Please correct the following error(s):
                                            </strong>
                                            <ul></ul>
                                        </div>

                                        <div class="contactform form-inline">
                                            {/* <label for="formContact-element-0">form_name</label>
                                            <input type="text" name="form_name"
                                                autocomplete="off" class="hidden" tabindex="-1" id="formContact-element-0" /> */}
                                            <div class="row">
                                                <div class="col span_1_of_2">
                                                    <label for="formContact-element-3"><span class="required">*
                                                    </span>First Name</label>
                                                    <input type="text" name="firstname" required size="32" value={firstName} onChange={(e) => setFirstName(e.target?.value)} />
                                                </div>
                                                <div class="col span_1_of_2">
                                                    <label for="formContact-element-6"><span class="required">*
                                                    </span>Last Name</label>
                                                    <input type="text" name="lastname" required size="32"
                                                        value={lastName} onChange={(e) => setLastName(e.target?.value)} />
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col span_1_of_2">
                                                    <label for="formContact-element-11">Phone</label>
                                                    <input type="tel" name="phone" size="32" value={phoneNum} onChange={(e) => setPhoneNum(e.target?.value)} />
                                                </div>
                                                <div class="col span_1_of_2">
                                                    <label for="formContact-element-14"><span class="required">*
                                                    </span>Email</label>
                                                    <input type="email" name="email" required size="32"
                                                        value={email} onChange={(e) => setEmail(e.target?.value)} />
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col span_1_of_2 clough-contact">
                                                    {/* <fieldset>
                                                        <legend>Quarterly Letter Signup?</legend>
                                                        <label class="checkbox">
                                                            <input type="checkbox" name="quarterly_letter[]" checked={quarterlyLetterSignUp}
                                                                onChange={(event) => setQuarterlyLetterSignUp(!quarterlyLetterSignUp)} />
                                                            Yes
                                                        </label>
                                                    </fieldset> */}
                                                    <fieldset>
                                                        <legend>Investment Professional?</legend>
                                                        <label class="checkbox">
                                                            <input type="checkbox" name="investment-professional[]" checked={investmentProfessional}
                                                                value="✓" onChange={(event) => setInvestmentProfessional(!investmentProfessional)} />
                                                            Select if you are an investment professional </label>
                                                    </fieldset>
                                                </div>
                                                <div class="col span_1_of_2 clough-contact">
                                                    <fieldset>
                                                        <legend>What funds are you interested in?</legend>
                                                        <label class="checkbox">
                                                            <input type="checkbox" name="fund_interest_GLV[]" value="✓" checked={activeGLV ? true : false} onChange={(event) => setActiveGLV(!activeGLV)} />
                                                            Clough Global Dividend and Income Fund </label>
                                                        <label class="checkbox"> <input
                                                            id="formContact-element-32-0" type="checkbox" name="fund_interest_GLQ[]" value="✓" checked={activeGLQ ? true : false} onChange={(event) => setActiveGLQ(!activeGLQ)} />
                                                            Clough Global Equity Fund </label>
                                                        <label class="checkbox">
                                                            <input type="checkbox" name="fund_interest_GLO[]" value="✓" checked={activeGLO ? true : false} onChange={(event) => setActiveGLO(!activeGLO)} />
                                                            Clough Global Opportunities Fund </label>
                                                        {/* <label class="checkbox">
                                                            <input type="checkbox" name="fund_interest_Long-Short[]" value="✓" checked={activeGLongShort ? true : false} onChange={(event) => setActiveGLongShort(!activeGLongShort)} />
                                                            Clough Global Long/Short Fund </label> */}
                                                    </fieldset>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col span_2_of_2"> <span id="charNum">1000/1000 Characters Remaining</span>
                                                    <label
                                                        for="formContact-element-41">Comments</label>
                                                    <textarea rows="5" name="comments"
                                                        placeholder=" " width="100%" maxlength="1000" class="form__comments"
                                                        value={comment} onChange={(e) => setComment(e.target?.value)} ></textarea>
                                                </div>
                                                
                                                <div class="row">
                                                    <div class="col span_2_of_2">
                                                        <ReCAPTCHA ref={recaptcha} sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY} onChange={()=>{setNotify(null)}}/>
                                                        <div className="form-actions" style={{marginTop:"10px"}}>
                                                            <button className="form__submit button--primary btn btn-primary" style={{ background: disabled ? '#d5d0c9' : '#000', marginRight: '10px' }}
                                                                onClick={() => !disabled && sumbitForm()}>Submit</button>
                                                            <button className="form__cancel button--secondary" onClick={() => clearForm()}>Cancel</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    {notify && <p className="pulsate" style={{ ...notifStyle, color: notify === "Email Sent!" ? "forestgreen" : notify === "Some Error Occured!" ? "tomato" : "#ccc" }}>{notify}</p>}
                </div>
            </div>
        </div>
    );
}

export default ContactUs;
