import React, { useEffect, useRef, useState } from "react";

import "./style.css";

export default function VideoThumbnail() {

    const getVimeoVideoID = (url) => {
        const regex = /vimeo\.com\/(\d+)/;
        const matches = url.match(regex);
        return matches ? matches[1] : null;
    };

    const fetchVimeoThumbnail = async (videoID) => {
        const response = await fetch(`https://vimeo.com/api/v2/video/${videoID}.json`);
        const data = await response.json();
        return data[0].thumbnail_large;
    };

    const videoUrl = "https://vimeo.com/935010295"
    const [thumbnailUrl, setThumbnailUrl] = useState(null);

    useEffect(() => {
        const videoID = getVimeoVideoID(videoUrl);
        if (videoID) {
            fetchVimeoThumbnail(videoID).then((url) => setThumbnailUrl(url));
        }
    }, [videoUrl]);

    return (
        (videoUrl && thumbnailUrl) ?
        <div className="slider-video-container">
            <div className="thumbnail-container">
                <div className="thumbnail-header">Featured Interview</div>
                <div className="thumbnail-flex-container" onClick={() => window.open(videoUrl, '_blank')}>
                    <img
                        src={thumbnailUrl}
                        alt="Video Thumbnail"
                    />
                    <div className="play-icon">
                        <svg viewBox="0 0 68 48" width="68" height="48">
                            <path className="ytp-large-play-button-bg" d="M66.52,7.53a8.13,8.13,0,0,0-5.74-5.76C57.56,1,34,1,34,1S10.44,1,7.22,1.77A8.13,8.13,0,0,0,1.48,7.53,85.39,85.39,0,0,0,0,24,85.39,85.39,0,0,0,1.48,40.47a8.13,8.13,0,0,0,5.74,5.76C10.44,47,34,47,34,47s23.56,0,26.78-.77a8.13,8.13,0,0,0,5.74-5.76A85.39,85.39,0,0,0,68,24,85.39,85.39,0,0,0,66.52,7.53ZM27,36V12L45,24Z"></path>
                            <path d="M 45,24 27,14 27,34" fill="#fff"></path>
                        </svg>
                    </div>
                </div>
                <div className="thumbnail-description">Chuck Clough Joins Bloomberg</div>
            </div>
        </div> : null
    );
}
