import React, { useEffect, useState, useRef } from "react";
import Footer from "../../components/footer";
import Header from "../../components/header";
import { teamBio } from '../../utils/teamBio'

function Bios() {
    const [scrollVisible, setScrollVisible] = useState(false);

    useEffect(() => {
        window.addEventListener('scroll', () => setScrollVisible(window.pageYOffset))
    }, [])

    return (
        <>
            <Header />
            <main id="mainContent">
                <div class="main_content">
                    <div class={`hero-short hero-short__img-3 ${scrollVisible > 100 ? 'hero-short--collapsed' : ''}`}>
                        <div class="container">

                            <div class="col span_6_of_10 lg-full">
                                <h2 class="hero-short__ticker--static mod1">The Team</h2>
                            </div>

                        </div>
                    </div>
                    <div class="container inner-content">
                        <div className="spacer" />
                        <div id="team-bios-page">
                            {teamBio?.length > 0 && teamBio?.map((team, idx) => {
                                return (
                                    <>
                                        <div class="row">
                                            <div class="col span_1_of_5">
                                                <p><img src={team?.image} /></p>
                                            </div>
                                            <div class="col span_4_of_5">
                                                <p><b dangerouslySetInnerHTML={{ __html: team?.name }} /></p>
                                                <p dangerouslySetInnerHTML={{ __html: team?.role }} />
                                                <p dangerouslySetInnerHTML={{ __html: team?.body }} />
                                            </div>
                                        </div>
                                        <div className="spacer" />
                                    </>
                                )
                            })}                       
                        </div>

                        <div className="spacer" />

                    </div>
                </div>
            </main>

            <Footer />
        </>
    );
}

export default Bios;