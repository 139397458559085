import CharlesClough from '../../src/assets/images/team/charles_clough.jpeg'
import Vince from '../../src/assets/images/team/vince.jpg'
import MichaelHearle from '../../src/assets/images/team/michael_hearle.jpeg'
import AnupamBose from '../../src/assets/images/team/anupam_bose.jpeg'
import JoshHowland from '../../src/assets/images/team/josh_howland.jpeg'
import KevinMcNally from '../../src/assets/images/team/kevin_mcnally.jpeg'
import NoelleTune from '../../src/assets/images/team/noelle_tune.jpeg'
import BillWhelan from '../../src/assets/images/team/bill_whelan.jpeg'
export const teamBio = [
    {
        image: CharlesClough,
        name: "Charles I. Clough, Jr., CFA<sup>1</sup>",
        role: "Chairman, CIO, Partner &amp; Portfolio Manager: Clough Closed-End Funds",
        body: ` Chuck Clough is the Chairman and Chief Investment Officer of Clough Capital. He has been active in the securities and investment business for over 56 years.<br />
        <br />
        Prior to founding Clough Capital, Chuck served as the Chief Global Investment Strategist from 1987 through 1999 at Merrill Lynch &amp; Co., Inc. During this time, he advised many of the world's top institutions and investors on portfolio strategy and was named to the Institutional Investor All-America Research Team for 12 years<sup>2</sup>. He earned a place as the top ranked strategist on Wall Street on three separate occasions and has been consistently recognized as a top strategist in areas such as U.S. equities, global investments, and fixed income.<br />
        <br />
        Prior to his tenure at Merrill Lynch, Chuck was Director of Investment Policy and Chief Strategist at Cowen &amp; Co. Previously, he served as the Director of Research and a Portfolio Manager at the Boston Company, a Portfolio Manager at Colonial Management Associates and a Vice President and Senior Research Analyst for both Donaldson, Lufkin &amp; Jenrette and Alliance Capital Management Company. Chuck serves on the boards of a number of hospitals, as well as educational and charitable institutions, including his alma mater, Boston College. Chuck graduated magna cum laude with a major in History and a minor in Economics from Boston College and earned an MBA at the University of Chicago. He has been a CFA<sup>®</sup> charterholder since 1979. Chuck is an ordained permanent deacon in the Roman Catholic Archdiocese of Boston and serves in that capacity at his local parish in Concord, MA.<br />
        <br /> 
        <div class="disclosure"><sup>1</sup>The CFA charter is a qualification for finance and investment professionals, particularly in the fields of investment management and financial analysis of stocks, bonds and their derivative assets. The program focuses on portfolio management and financial analysis, and provides a generalist knowledge of other areas of finance. It is a graduate level self study program offered by the CFA Institute (formerly AIMR) to investment and financial professionals. A candidate who successfully completes the program and meets other professional requirements is awarded a "CFA charter" and becomes a "CFA charterholder".<br />
        <br />
        </div><p></p>`
    },
    // <sup>2</sup>In polling for the 'All-America Research Team', Institutional Investor sent questionnaires covering several categories and investment sectors to research directors and the chief investment officers of major money management firms. In addition, they contacted institutional investors from client lists submitted by Wall Street research directors and sent questionnaires to analysts and portfolio managers at many top institutions. Rankings were determined strictly by using numerical scores. The number of votes awarded to each analyst were weighted based on the size of the institution responding and the place (first, second, third, or fourth) it awarded to that analyst.
    {
        image: Vince,
        name: "Vince Lorusso",
        role: "President & CEO, Partner & Portfolio Manager",
        body: `Vince has been in the investment industry for 25 years, including a prior tenure with Clough Capital Partners from 2004-2020 when he served as a Research Analyst, Portfolio Manager & Partner.  Prior to rejoining Clough in 2023, Vince had been the President, CEO and Portfolio Manager of Changebridge Capital, LLC.  Changebridge was Co-Founded by Vince in 2020 to provide actively managed strategies to investors in the ETF structure.  Those ETFs are now advised by Clough Capital Partners, with Vince remaining as Portfolio Manager of the ETFs, a role he has served since the inception of the two strategies. Vince’s career in finance began in 1998 with New England Funds, an entity which would soon be integrated into Natixis Asset Management.  Over a span of six years with Natixis-affiliates, Vince held several foundational roles in his career including Investor Representative and Project Manager, and most recently as a Senior Investment Consultant for Natixis. For more than a decade, Vince has also served as a member of the Investment Committee for Louisa May Alcott’s Orchard house, which serves as a historic museum in Concord, Massachusetts. Vince also serves on the Investment Committee for the Nashoba Brooks School, where he has been a Board Member, including a term as President of the Board of Trustees. Vince holds an M.S. in Finance and a B.S. in Finance & English, both from Boston College.<br />
        `,
    },
    // {
    //     image: MichaelHearle,
    //     name: "Michael Hearle",
    //     role: "CEO, Co-CIO &amp; Partner",
    //     body: `Michael Hearle joined Clough Capital in 2012 and has over 28 years of industry experience in institutional research and investments. In 2019, Michael was appointed Chief Executive Officer and Co-Chief Investment Officer of Clough Capital. He is responsible for the healthcare investments at the Firm across all sub-sectors. Prior to joining Clough Capital, Michael covered the global healthcare sector as a Principal at Pequot Capital Management from 2005 to 2008 and later as a Portfolio Manager at Millennium Partners from 2008 to 2010 and Noble Partners from 2010 to 2012. Michael began his career in 1994 with Merrill Lynch &amp; Co. where he was a Vice President in the International Institutional Equity Group. He remained in equity research until 2004 with Leerink Partners (now SVB Leerink), where he ultimately served as a Principal and Co-Director of Equity Research. His team at Leerink Partners ranked first from 2002 to 2004 in the Institutional Investor 'Best of the Boutiques' poll for Specialty Pharmaceutical research<sup>3</sup>. Michael holds a B.A. from The College of William &amp; Mary.<br />
    //     <br />
    //     `,
    // },
    // <div class="disclosure"><sup>3</sup>In polling for the 'Best of Boutiques', Institutional Investor asked analysts and portfolio managers at asset management and hedge funds to name the boutiques or regional firms that they believed had done the best research work over the preceding 12-month period in each of the industry and macroeconomic categories polled in the All-America ranking. Scores were produced by weighting the number of votes awarded to a firm based on the size of the voting institution. The rankings were determined based on the numerical score each firm received.</div><p></p></div>
    // {
    //     image: AnupamBose,
    //     name: "Anupam Bose",
    //     role: "Partner",
    //     body: `Anupam Bose joined Clough Capital in 2014 and has over 19 years of industry experience with a focus on equity investments in the technology sector. Prior to joining the Firm, Anupam worked as a Technology Research Analyst at Westfield Capital Management and at McKinsey &amp; Company where he led projects in corporate finance and private equity practices. He also worked at The Carlyle Group where he assessed growth capital investments. Anupam earned his undergraduate degree in Electrical Engineering from the Indian Institute of Technology and went on to earn an MBA from the MIT Sloan School of Management.`,
    // },
    // {
    //     image: JoshHowland,
    //     name: "Josh Howland, CFA<sup>1</sup>",
    //     role: "Partner &amp; Head Trader",
    //     body: `Josh Howland has served as Head Trader since 2001 offering special expertise in implementing option strategies. Prior to joining the Firm in 2000 as Operations Manager, Josh was a Senior Accountant at Investors Bank and Trust Company. Josh is a CFA<sup>®</sup> charterholder and holds a B.S. in Business Administration from the University of Rhode Island.<br />
    //     <br />
    // <div class="disclosure"><sup>1</sup>The CFA charter is a qualification for finance and investment professionals, particularly in the fields of investment management and financial analysis of stocks, bonds and their derivative assets. The program focuses on portfolio management and financial analysis, and provides a generalist knowledge of other areas of finance. It is a graduate level self study program offered by the CFA Institute (formerly AIMR) to investment and financial professionals. A candidate who successfully completes the program and meets other professional requirements is awarded a "CFA charter" and becomes a "CFA charterholder".
    // </div><p></p></div></div>`,
    // },
    // {
    //     image: KevinMcNally,
    //     name: "Kevin McNally",
    //     role: "Portfolio Manager",
    //     body: `Kevin McNally joined Clough Capital in 2014 and has over 30 years of experience focusing almost exclusively on closed-end funds (CEFs) and exchange-traded funds (ETFs). Prior to joining the Firm, Kevin was the Director of Closed-End Funds at ALPS Fund Services, Inc. from 2003 to 2014, where he was instrumental in launching approximately $13 billion in total assets of CEFs. Prior to that, he was Director of Closed-End Fund and ETF Research at Smith Barney, a division of Citigroup Global Markets, Inc. from 1998 to 2003 and Director of CEF and ETF Marketing at Morgan Stanley Dean Witter Discover &amp; Co. from 1997 to 1998. Previously, he was an Analyst covering CEFs in the Mutual Fund Research Department at Merrill Lynch, Pierce, Fenner &amp; Smith, Inc. from 1994 to 1997, and also was Manager of the Closed-End Fund Marketing Department at Prudential Securities from 1992 to 1994. Kevin holds a B.A. from the University of Massachusetts at Amherst and an MBA in Finance from NYU Stern School of Business. Kevin currently serves on the board of trustees for the Firm's closed-end mutual fund products.`
    // },
    // {
    //     image: NoelleTune,
    //     name: "Dr. Noelle Tune, M.D.",
    //     role: "Director",
    //     body: `Dr. Noelle Tune, M.D., joined Clough Capital as a Director in 2020 and has over 22 years of healthcare investment and industry experience. Prior to joining Clough Capital, Noelle worked at a major urban medical center in the Boston area as an emergency medicine physician and continues to practice medicine. She completed her residency at Indiana University, where she served as a Chief Resident in her final year of training. Before entering medical school at the University of North Carolina at Chapel Hill, from 1999 to 2005, Noelle worked at Leerink Partners (now SVB Leerink) covering specialty pharmaceuticals, biotechnology and healthcare services. Noelle then started her own private label research offering through Soleil Securitas. Noelle earned a B.A. in Russian and Soviet Studies from Harvard University. While at Harvard, she was captain of the women's rowing team and currently serves as a Director for the Friends of Harvard and Radcliffe Rowing.`
    // },
    {
        image: BillWhelan,
        name: "Bill Whelan",
        role: "Partner & Portfolio Manager: Clough Closed-End Funds",
        body: `Bill Whelan joined Clough Capital in 2014 and has over 16 years of experience in the investment management industry. In 2023, Bill was named Co-Portfolio Manager of the Firm's three closed-end funds. Prior to joining the Firm, Bill was an Investment Principal at Partners Capital, a private investment office focused on multi-asset class investing, from 2010 to 2014. Previously, Bill was an Equity Research Analyst at Millennium Management, a multi-strategy hedge fund, from 2008 to 2009 and at Fidelity Management and Research from 2005 to 2008. Bill earned a B.A. in Economics from Harvard University.`
    },
]