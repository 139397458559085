import React, { useEffect, useState } from "react";
import Footer from "../../components/footer";
import Header from "../../components/header";
import { Link } from "react-router-dom";
import ContactUs from "../../modal-components/ContactUs";
import './style.css'

function Sitemap() {
    const [contactUsForm, setContactUsForm] = useState(false);
    const [scrollVisible, setScrollVisible] = useState(null);
    
    useEffect(() => {
        window.addEventListener('scroll', () => setScrollVisible(window.pageYOffset))
    }, [])

    return (
        <>
            <Header />
            <main id="mainContent">

                <div class="main_content">

                    <div class={`hero-short hero-short__img-3 ${scrollVisible > 10 ? 'hero-short--collapsed' : ''}`}>
                        <div class="container">

                            <div class="col span_6_of_10 lg-full">
                                <h2 class="hero-short__ticker--static mod1">Site Map</h2>
                            </div>

                        </div>
                    </div>

                    <div class="container inner-content clearfix video-element">
                        <div class="spacer" />
                        <div class="row">
                            <div class="col span_1_of_2">
                                <p><Link to="/" class="sitemap-heading">Index</Link></p>
                                <p class="indent"><Link to="/home/close_end_funds">Closed-End Funds Daily Data</Link></p>
                                <p><a onClick={() => setContactUsForm(true)} class="sitemap-heading">Contact Us</a></p>
                            </div>
                            <div class="col span_1_of_2">
                                <p><Link to="/the-advisor" class="sitemap-heading">The Advisor</Link></p>
                                <p class="indent"><Link to="/bios">Bios</Link></p>
                                <p><Link to="/glossary" class="sitemap-heading">Glossary</Link></p>
                                <p><Link to="/risks" class="sitemap-heading">Risks</Link></p>

                            </div>
                        </div>
                        <div class="row">
                            <div class="col span_1_of_2">
                                <p><Link to="/glv" class="sitemap-heading">GLV</Link></p>
                                <p class="indent"><Link to="/glv?performance?cumulative">Cumulative Performance</Link></p>
                                <p class="indent"><Link to="/glv?performance?calendar_year">Calendar Year Performance</Link></p>
                                <p class="indent"><Link to="/glv?holdings?all_holdings">All Holdings</Link></p>
                                <p class="indent"><Link to="/glv?distributions?previous_year">Previous Years Distributions</Link></p>
                                <p class="indent"><Link to="/resources/glv">Resources</Link></p>
                                <p class="indent"><Link to="/resources/glv?fund_news?previous_year">Fund News - Previous Years</Link></p>
                            </div>
                            <div class="col span_1_of_2">
                                <p><Link to="/glq" class="sitemap-heading">GLQ</Link></p>
                                <p class="indent"><Link to="/glq?performance?cumulative">Cumulative Performance</Link></p>
                                <p class="indent"><Link to="/glq?performance?calendar_year">Calendar Year Performance</Link></p>
                                <p class="indent"><Link to="/glq?holdings?all_holdings">All Holdings</Link></p>
                                <p class="indent"><Link to="/glq?distributions?previous_year">Previous Years Distributions</Link></p>
                                <p class="indent"><Link to="/resources/glq">Resources</Link></p>
                                <p class="indent"><Link to="/resources/glq?fund_news?previous_year">Fund News - Previous Years</Link></p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col span_1_of_2">
                                <p><Link to="/glo" class="sitemap-heading">GLO</Link></p>
                                <p class="indent"><Link to="/glo?performance?cumulative">Cumulative Performance</Link></p>
                                <p class="indent"><Link to="/glo?performance?calendar_year">Calendar Year Performance</Link></p>
                                <p class="indent"><Link to="/glo?holdings?all_holdings">All Holdings</Link></p>
                                <p class="indent"><Link to="/glo?distributions?previous_year">Previous Years Distributions</Link></p>
                                <p class="indent"><Link to="/resources/glo">Resources</Link></p>
                                <p class="indent"><Link to="/resources/glo?fund_news?previous_year">Fund News - Previous Years</Link></p>
                            </div>
                            {/* <div class="col span_1_of_2">
                                <p><Link to="/long-short" class="sitemap-heading">Clough Global Long/Short Fund</Link></p>
                                <p class="indent"><Link to="/long-short?fund_details?class_inv">Class Inv Daily Data</Link></p>
                                <p class="indent"><Link to="/long-short?fund_details?class_a">Class A Daily Data</Link></p>
                                <p class="indent"><Link to="/long-short?fund_details?class_c">Class C Daily Data</Link></p>
                                <p class="indent"><Link to="/long-short?performance?cumulative">Cumulative Performance</Link></p>
                                <p class="indent"><Link to="/long-short?performance?calendar_year">Calendar Year Performance</Link></p>
                                <p class="indent"><Link to="/long-short?holdings?all_holdings">All Holdings</Link></p>
                                <p class="indent"><Link to="/long-short?distributions?previous_year">Previous Years Distributions</Link></p>
                                <p class="indent"><Link to="/resources/long-short">Resources</Link></p>
                                <p class="indent"><Link to="/regulatory-reports">Regulatory Reports</Link></p>
                            </div> */}
                        </div>


                        <div class="spacer" />
                    </div>



                </div>

            </main>
            <Footer />
            {contactUsForm &&
                <ContactUs
                    setClosehandler={setContactUsForm} />
            }
        </>
    );
}

export default Sitemap;