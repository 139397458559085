import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ApolloClient, InMemoryCache, ApolloProvider, gql } from '@apollo/client';
import config from "./env";
import Home from "./pages/Home";
import Advisor from "./pages/Advisor";
import Glossary from "./pages/Glossary";
import Sitemap from "./pages/Sitemap";
import Risk from "./pages/Risk";
import Bios from "./pages/Bios";
import Resources from "./pages/Resources";
import RegulatoryReports from "./pages/RegulatoryReports";
import Fund from "./pages/Fund";
import './App.css';

const { graphQLEndpoint } = config;

const client = new ApolloClient({
  cache: new InMemoryCache(),
  uri: graphQLEndpoint,
});

function App() {

  return (
    <>
      <ApolloProvider client={client}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} exact />
            <Route path="/home" element={<Home />} />
            <Route path="/home/:section" element={<Home />} />
            <Route path="/the-advisor" element={<Advisor />} />
            <Route path="/glossary" element={<Glossary />} />
            <Route path="/sitemap" element={<Sitemap />} />
            <Route path="/bios" element={<Bios />} />
            <Route path="/risks" element={<Risk />} />
            <Route path="/regulatory-reports" element={<RegulatoryReports />} />
            <Route path="/:fundID" element={<Fund />} />
            <Route path="/:fundID/:section" element={<Fund />} />
            <Route path="/resources/:resourceID" element={<Resources />} />
          </Routes>
        </BrowserRouter>
      </ApolloProvider>
    </>
  );
}

export default App;
