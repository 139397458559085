import React, { useState } from "react";
import closeIcon from '../../assets/images/btn-close--modal.svg'
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { EffectFade, Navigation, Pagination } from "swiper";
import './style.css'

function AboutUs(props) {
    const { setClosehandler, data, activeIndex } = props

    return (
        <div id="modalOverlay">
            <div className="overlay--bg" onClick={() => setClosehandler(false)}></div>
            <div className="overlay--box about-us">
                <div className="overlay--container">
                    <div className="overlay-close" onClick={() => setClosehandler(false)}>
                        <img src={closeIcon} width="25px" height="25px" alt="Close-Button" />
                    </div>
                    <div className="container-wrapper">
                        <Swiper
                            spaceBetween={30}
                            effect={"fade"}
                            navigation={true}
                            loop={true}
                            pagination={{
                                clickable: true,
                            }}
                            modules={[EffectFade, Navigation]}
                            className="bio-info-swiper"
                            initialSlide={activeIndex || 0}
                        >
                            {data?.map((item, idx) => {
                                return (
                                    <SwiperSlide>
                                        <div className="about-us-wrapper" key={idx}>
                                            <div class="">
                                                <img class="modal--bios__img" alt={item?.name} src={item?.image} style={{ opacity: "1" }} />
                                                <h4 class="modal--bios__name" style={{ opacity: "1" }} dangerouslySetInnerHTML={{ __html: item?.name }} />
                                                <h5 class="modal--bios__title" style={{ opacity: "1" }} dangerouslySetInnerHTML={{ __html: item?.role }} />
                                            </div>
                                            <div class="body-content">
                                                <p style={{ opacity: "1" }}  dangerouslySetInnerHTML={{ __html: item?.body }} />
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                )
                            })}
                        </Swiper>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AboutUs;
