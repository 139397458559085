import React, { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import moment from "moment";
import { RESOURCES_QUERY } from '../../utils/queries'
import downloadIcon from '../../assets/images/download_icon.svg'
import docLinkIcon from '../../assets/images/doclink_icon.svg'

function GLQResource(props) {
    const { ref1, ref2, ref3, ref4, activeTab, documentData } = props

    const CURRENT_YEAR = 'current_year'
    const PREVIOUS_YEAR = 'previous_year'
    const [activeYear, setActiveYear] = useState(CURRENT_YEAR)
    const [currentList, setCurrentList] = useState([]);
    const [regulatoryList, setRegulatoryList] = useState([]);
    const [taxList, setTaxList] = useState([]);
    const [fundList, setFundList] = useState([]);
    const currentYear = new Date()?.getFullYear() || '2023'
    const [
        fetchResources,
        { data: resourceData, loading: resourceLoading, error: resourceError },
    ] = useLazyQuery(RESOURCES_QUERY);

    useEffect(() => {
        if (activeTab) {
            setActiveYear(activeTab)
        }
    }, [activeTab])

    useEffect(() => {
        fetchResources({ variables: { ticker: 'GLQ', documentType: documentData, documentTypes: documentData } });
    }, [documentData])

    const getUniqueFunds = (data) => {
        let sortArray = [...new Set(data?.flat().sort((a, b) => b['createdAt']?.toString().localeCompare(a['createdAt']?.toString())).map(item => item))]
        const uniqueIds = [];
        const uniqueArray = sortArray.filter(element => {
            const isDuplicate = element['friendlyName'] !== "Clough Global Allocation Fund Announces Tax Information" && uniqueIds.includes(element['friendlyName']);
            if (!isDuplicate) {
                uniqueIds.push(element['friendlyName']);

                return true;
            }
            return false;
        });
        return uniqueArray
    }

    useEffect(() => {
        let completeFund = resourceData?.resources?.completeFundData
        let currentUpdates = []
        let regulatoryUpdates = [{ friendlyName: 'SEC Filings', type: 'redirect', createdAt: "", url: 'https://www.sec.gov/edgar/browse/?CIK=1316463&owner=exclude' }]
        let taxUpdates = []
        let fundUpdates = []
        completeFund?.length > 0 && completeFund?.map((data) => {
            if ((data?.type == 'monthly_factsheet' || data?.type == 'monthly-factsheet' || data?.type == 'Monthlyfactsheet' || data?.type == 'education') && data?.funds?.length > 0) {
                currentUpdates?.push(data?.funds)
            }
            if ((data?.type == 'semi_annual_report' || data?.type == 'semi-annual-report') && data?.funds?.length > 0) {
                regulatoryUpdates.push(data?.funds)
            }
            if ((data?.type == 'annual_report' || data?.type == 'annual-report') && data?.funds?.length > 0) {
                regulatoryUpdates.push(data?.funds)
            }
            if ((data?.type == 'prospectus_supplement' || data?.type == 'prospectus-supplement') && data?.funds?.length > 0) {
                regulatoryUpdates.push(data?.funds)
            }
            if ((data?.type == 'prospectus') && data?.funds?.length > 0) {
                regulatoryUpdates.push(data?.funds)
            }
            if ((data?.type == 'regulatory') && data?.funds?.length > 0) {
                regulatoryUpdates.push(data?.funds)
            }
            if ((data?.type == 'fundnews') && data?.funds?.length > 0) {
                fundUpdates.push(data?.funds)
            }
            if ((data?.type == 'irs_form' || data?.type == 'irs-form') && data?.funds?.length > 0) {
                taxUpdates.push(data?.funds)
            }
        })
        // const uniqueReport = [...new Set(regulatoryUpdates?.flat().sort((a, b) => b['createdAt']?.toString().localeCompare(a['createdAt']?.toString())).map(item => item))];
        // const uniqueCurrent = [...new Set(currentUpdates?.flat().sort((a, b) => b['createdAt']?.toString().localeCompare(a['createdAt']?.toString())).map(item => item))];
        const uniqueTax = [...new Set(taxUpdates?.flat().sort((a, b) => b['createdAt']?.toString().localeCompare(a['createdAt']?.toString())).map(item => item))];
        const uniqueFund = [...new Set(fundUpdates?.flat().sort((a, b) => b['createdAt']?.toString().localeCompare(a['createdAt']?.toString())).map(item => item))];
        setCurrentList(getUniqueFunds(currentUpdates))
        setRegulatoryList(getUniqueFunds(regulatoryUpdates))
        setTaxList(uniqueTax)
        setFundList(uniqueFund)
    }, [resourceData])

    const getActiveYear = (records, activeDistribution) => {        
        let tmpRecords = []
        if (activeDistribution == CURRENT_YEAR) {
            tmpRecords = records?.filter((x) => moment(x.createdAt)?.year() == currentYear && (x.friendlyName !== '4Q2022 Quarterly Commentary' && x.friendlyName !== 'Clough Capital Partners Statement on Prohibited Securities'))
        } else {
            tmpRecords = records?.filter((x) => moment(x.createdAt)?.year() !== currentYear && (x.friendlyName !== '4Q2022 Quarterly Commentary' && x.friendlyName !== 'Clough Capital Partners Statement on Prohibited Securities'))
        }
        return tmpRecords
    }
    const getAlert = (url) => {
        let message = 'You are now leaving www.cloughcefs.com and entering a linked web site. This Fund and ALPS Portfolio Solutions Distributor, Inc. are not responsible for the content set forth on the linked website. No judgment or warranty is made with respect to the accuracy, timelinesss, or suitability of the content of information on the site regarding Clough Global Funds and ALPS Portfolio Solutions Distributor, Inc. take no responsibility thereof. To return to the Clough Global Funds simply close the second browser window. Thank you. Click "OK" to continue.'
        if (window.confirm(message)) {
            window.open(`${url}`, '_blank', 'noreferrer');
        }
    }

    const  additionalRegulatoryList = [
        {name: "N-PX Filing", createdAt: "04-09-2024", url:"https://www.sec.gov/Archives/edgar/data/1316463/000183988224027788/0001839882-24-027788-index.htm"}
    ]
    return (
        <>
            <div class="container inner-content video-element">

                <div id="education" class="waypoint__subnav" ref={ref1}></div>
                <h3>RESOURCES</h3>
                <br /><br />

                <h3 class="mod2 underline">Education</h3>


                <table class="table--mod-1 table_alt1">
                    <tbody><tr>
                        <td><b>Document</b></td>
                        <td><b>Date</b></td>
                        <td><b>Download</b></td>
                    </tr>
                        {currentList?.length > 0 && currentList?.map((data, idx) => {
                            return (
                                <tr key={idx}>
                                    <td>{data?.friendlyName}</td>
                                    <td>{data.createdAt ? moment(data.createdAt).format("MM/DD/yyyy") : '-'}</td>
                                    <td>
                                        <a href={`${process.env.REACT_APP_API_LINK}download_resource/?id=${data?.id}&ticker=${data?.ticker}`} target="_blank" style={{}}>
                                            <img src={downloadIcon} width="22" height="22" alt="Download Section 19a PDF" title="Download" />
                                        </a>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody></table>
                <div className="spacer" />
            </div>

            <div class="container">

                <h3 class="mod2 underline">Regulatory</h3>
                <div id="regulatory" class="waypoint__subnav" ref={ref2}></div>
                <div id="regulatoryTableWrapper_full" class="tableHorizontalScroll" style={{ overflowX: "hidden" }}>
                    <table class="table--mod-1 table_alt1">
                        <tbody><tr>
                            <td><b>Document</b></td>
                            <td><b>Date</b></td>
                            <td><b>Download</b></td>
                        </tr>
                            {regulatoryList?.length > 0 && regulatoryList?.map((data, idx) => {
                                return (
                                    <tr key={idx}>
                                        <td>{data?.friendlyName}</td>
                                        <td>{data.createdAt ? moment(data.createdAt).format("MM/DD/yyyy") : ''}</td>
                                        {data?.type == 'redirect' ?
                                            <td>
                                                <a onClick={() => getAlert(data?.url)} style={{}}>
                                                    <img src={docLinkIcon} width="22" height="22" alt="Download Section 19a PDF" title="Download" />
                                                </a>
                                            </td>
                                            :
                                            <td>
                                                <a href={`${process.env.REACT_APP_API_LINK}download_resource/?id=${data?.id}&ticker=${data?.ticker}`} target="_blank" style={{}}>
                                                    <img src={downloadIcon} width="22" height="22" alt="Download Section 19a PDF" title="Download" />
                                                </a>
                                            </td>
                                        }
                                    </tr>
                                )
                            })}
                            {additionalRegulatoryList?.length > 0 && additionalRegulatoryList?.map((data, idx) => {
                                return (
                                    <tr key={idx}>
                                        <td>{data?.name}</td>
                                        {/* <td>{data.createdAt ? moment(data.createdAt).format("MM/DD/yyyy") : '-'}</td> */}
                                        <td></td>
                                        <td>
                                            <a href={data?.url} target="_blank">
                                                <img src={downloadIcon} width="22" height="22" alt={data?.name} title="Download" />
                                            </a>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>

                </div>
                <div id="regulatoryTableIndicator_full" class="tableOverflowIndicatorMobile">← Swipe to see additional data →</div>

                <div className="spacer" />
            </div>

            <div class="container">


                <div id="tax-wrapper">
                    <h3 class="mod2 underline">Tax Information</h3>
                    <div id="tax_information" class="waypoint__subnav" ref={ref3}></div>

                    <div id="taxTableWrapper_full" class="tableHorizontalScroll" style={{ overflowX: "hidden" }}>
                        <table class="table--mod-1 table_alt1">
                            <tbody><tr>
                                <td><b>Document</b></td>
                                <td><b>Date</b></td>
                                <td><b>Download</b></td>
                            </tr>
                                {taxList?.length > 0 && taxList?.map((data, idx) => {
                                    return (
                                        <tr key={idx}>
                                            <td>{data?.friendlyName}</td>
                                            <td>{data.createdAt ? moment(data.createdAt).format("MM/DD/yyyy") : '-'}</td>
                                            <td>
                                                <a href={`${process.env.REACT_APP_API_LINK}download_resource/?id=${data?.id}&ticker=${data?.ticker}`} target="_blank" style={{}}>
                                                    <img src={downloadIcon} width="22" height="22" alt="Download Section 19a PDF" title="Download" />
                                                </a>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody></table>
                    </div>
                    <div id="taxTableIndicator_full" class="tableOverflowIndicatorMobile">← Swipe to see additional data →</div>
                </div>

                <div className="spacer" />
            </div>

            <div class="container">

                <h3 class="mod2 underline">Fund News</h3>
                <div id="fund_news" class="waypoint__subnav" ref={ref4}></div>
                <div class="tabs" id="tabs__resourses_news">

                    <ul class="tabs__list">
                        <li class="tabs__list-item">
                            <a class={`tabs__tab ${activeYear == CURRENT_YEAR ? 'active' : ''} tab_override`} onClick={() => setActiveYear(CURRENT_YEAR)}>Current Year</a>
                        </li>
                        <li class="tabs__list-item">
                            <a class={`tabs__tab ${activeYear == PREVIOUS_YEAR ? 'active' : ''} tab_override`} onClick={() => setActiveYear(PREVIOUS_YEAR)}>Previous Years</a>
                        </li>
                    </ul>

                    <div id="fundNews-wrapper">
                        {activeYear == CURRENT_YEAR &&
                            <div class="tableHorizontalScroll" style={{ overflowX: "hidden" }}>

                                <table class="table--mod-1 table_alt1" style={{}}>
                                    <tbody>
                                        <tr>
                                            <td><b>Document</b></td>
                                            <td><b>Date</b></td>
                                            <td><b>Download</b></td>
                                        </tr>
                                        {getActiveYear(fundList, activeYear)?.length > 0 && getActiveYear(fundList, activeYear)?.map((data, idx) => {
                                            return (
                                                <tr key={idx}>
                                                    <td>{data?.friendlyName}</td>
                                                    <td>{data.createdAt ? moment(data.createdAt).format("MM/DD/yyyy") : '-'}</td>
                                                    <td>
                                                        <a href={`${process.env.REACT_APP_API_LINK}download_resource/?id=${data?.id}&ticker=${data?.ticker}`} target="_blank" style={{}}>
                                                            <img src={downloadIcon} width="22" height="22" alt="Download Section 19a PDF" title="Download" />
                                                        </a>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        }
                        {activeYear == PREVIOUS_YEAR &&
                            <div class="tableHorizontalScroll" style={{ overflowX: "hidden" }}>

                                <table class="table--mod-1 table_alt1" style={{}}>
                                    <tbody>
                                        <tr>
                                            <td><b>Document</b></td>
                                            <td><b>Date</b></td>
                                            <td><b>Download</b></td>
                                        </tr>
                                        {getActiveYear(fundList, activeYear)?.length > 0 && getActiveYear(fundList, activeYear)?.map((data, idx) => {
                                            return (
                                                <tr key={idx}>
                                                    <td>{data?.friendlyName}</td>
                                                    <td>{data.createdAt ? moment(data.createdAt).format("MM/DD/yyyy") : '-'}</td>
                                                    <td>
                                                        <a href={`${process.env.REACT_APP_API_LINK}download_resource/?id=${data?.id}&ticker=${data?.ticker}`} target="_blank" style={{}}>
                                                            <img src={downloadIcon} width="22" height="22" alt="Download Section 19a PDF" title="Download" />
                                                        </a>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        }

                        <div id="fundNewsTableIndicator" class="tableOverflowIndicatorMobile">← Swipe to see additional data →</div>

                    </div>

                </div>

                <div className="spacer" />
            </div>

        </>
    );
}

export default GLQResource;
