import React, { useEffect, useState } from "react";
import Footer from "../../components/footer";
import Header from "../../components/header";

function Risk() {
    const [scrollVisible, setScrollVisible] = useState(null);

    useEffect(() => {
        window.addEventListener('scroll', () => setScrollVisible(window.pageYOffset))
    }, [])

    return (
        <>
            <Header />
            <main id="mainContent">

                <div class="main_content">

                    <div class={`hero-short hero-short__img-4 ${scrollVisible > 10 ? 'hero-short--collapsed' : ''}`}>
                        <div class="container">

                            <div class="col span_6_of_10 lg-full">
                                <h2 class="hero-short__ticker--static mod1">Risks</h2>
                            </div>

                        </div>
                    </div>


                    <div class="container inner-content">
                        <br />
                        <h3>CLOSED-END FUND RISKS</h3>

                        <b class="mod1">Investment and Market Risk</b>
                        <p>An investment in Common Shares is subject to investment risk, including the possible loss of the entire principal amount invested. An investment in Common Shares represents an indirect investment in the securities owned by the Funds, which are generally traded on a securities exchange or in the over-the-counter markets. The value of these securities, like other market investments, may move up or down, sometimes rapidly and unpredictably. The Common Shares at any point in time may be worth less than the original investment, even after taking into account any reinvestment of dividends and distributions.</p>



                        <b class="mod1">Key Adviser Personnel Risk</b>
                        <p>The Funds’ ability to identify and invest in attractive opportunities is dependent upon Clough Capital, its investment adviser. If one or more of the key individuals leaves Clough Capital, Clough Capital may not be able to hire qualified replacements at all, or may require an extended time to do so. This could prevent the Funds from achieving their investment objective.</p>

                        <b class="mod1">Issuer Risk</b>
                        <p>The value of an issuer’s securities may decline for a number of reasons which directly relate to the issuer, such as management performance, financial leverage and reduced demand for the issuer’s goods and services.</p>

                        <b class="mod1">Sector Risk</b>
                        <p>From time to time, based on market or economic conditions, the Funds may have larger investment positions in one or more sectors of the market. To the extent the Funds invest more heavily in one sector, industry, or sub-sector of the market, their performance may be more sensitive to developments that significantly affect those sectors, industries, or sub-sectors. An individual sector, industry, or sub-sector of the market may be more volatile, and may perform differently, than the broader market. The industries that constitute a sector may all react in the same way to economic, political or regulatory events. The Funds’ performance could also be affected if the sectors, industries, or sub-sectors do not perform as expected. Alternatively, the lack of exposure to one or more sectors or industries may adversely affect performance.</p>

                        <b class="mod1">Foreign Securities Risk</b>
                        <p>The Funds’ investments in securities of foreign issuers are subject to risks not usually associated with owning securities of U.S. issuers. These risks can include fluctuations in foreign currencies, foreign currency exchange controls, social, political and economic instability, differences in securities regulation and trading, expropriation or nationalization of assets, and foreign taxation issues. In addition, changes in government administrations or economic or monetary policies in the United States or abroad could result in appreciation or depreciation of the Funds’ securities. It may also be more difficult to obtain and enforce a judgment against a foreign issuer. To the extent the Funds focus their investments in a particular country or in countries within a particular geographic region, economic, political, regulatory and other conditions affecting such country or region may have a greater impact on the Funds than on more geographically diversified funds. Any foreign investments made by the Funds must be made in compliance with U.S. and foreign currency restrictions and tax laws restricting the amounts and types of foreign investments. The Funds will not invest more than 33% of their assets, at the time of acquisition, in securities (including equity and fixed income securities) of governments and companies in emerging markets, but has no other investment restrictions with respect to investing in foreign issuers.</p>

                        <b class="mod1">Common Stock Risk</b>
                        <p>To the extent the Funds invest in common stocks, those investments will be subject to special risks. Although common stocks have historically generated higher average returns than fixed income securities over the long term, common stocks also have experienced significantly more volatility in returns. Common stocks may be more susceptible to adverse changes in market value due to issuer specific events or general movements in the equities markets. A drop in the stock market may depress the price of common stocks held by the Funds. Common stock prices fluctuate for many reasons, including changes in investors’ perceptions of the financial condition of an issuer or the general condition of the relevant stock market, or the occurrence of political or economic events affecting issuers. For example, an adverse event, such as an unfavorable earnings report, may depress the value of common stock in which the Funds have invested; the price of common stock of an issuer may be particularly sensitive to general movements in the stock market; or a drop in the stock market may depress the price of most or all of the common stocks held by the Funds. Also, common stock of an issuer in the Funds’ portfolio may decline in price if the issuer fails to make anticipated dividend payments because, among other reasons, the issuer of the security experiences a decline in its financial condition. The common stocks in which the Funds will invest are structurally subordinated to preferred securities, bonds and other debt instruments in a company’s capital structure, in terms of priority to corporate income and assets, and therefore will be subject to greater risk than the preferred securities or debt instruments of such issuers. In addition, common stock prices may be sensitive to rising interest rates, as the costs of capital rise and borrowing costs increase.</p>

                        <b class="mod1">Emerging Markets Risk</b>
                        <p>Investing in securities of issuers based in underdeveloped emerging markets entails all of the risks of investing in securities of foreign issuers to a heightened degree. These heightened risks include: (i) greater risks of expropriation, confiscatory taxation, nationalization, and less social, political and economic stability; (ii) the smaller size of the market for such securities and a lower volume of trading, resulting in lack of liquidity and in price volatility; and (iii) certain national policies which may restrict the Funds’ investment opportunities including restrictions on investing in issuers or industries deemed sensitive to relevant national interests.</p>

                        <b class="mod1">REIT Risk</b>
                        <p>If the Funds invest in REITs, such investment will subject the Funds to various risks. The first, real estate industry risk, is the risk that the REIT share prices will decline because of adverse developments affecting the real estate industry and real property values. In general, real estate values can be affected by a variety of factors, including supply and demand for properties, the economic health of the country or of different regions, and the strength of specific industries that rent properties. The second, investment style risk, is the risk that returns from REITs, which typically are small or medium capitalization stocks, will trail returns from the overall stock market. The third, interest rate risk, is the risk that changes in interest rates may hurt real estate values or make REIT shares less attractive than other income producing investments.</p>
                        <p>Qualification as a REIT in any particular year is a complex analysis that depends on a number of factors. There can be no assurance that the entities in which the Funds invest with the expectation that they will be taxed as a REIT will qualify as a REIT. An entity that fails to qualify as a REIT, would be subject to a corporate level tax, would not be entitled to a deduction for dividends paid to its shareholders and would not pass through to its shareholders the character of income earned by the entity. If the Funds were to invest in an entity that failed to qualify as a REIT, such failure could drastically reduce the Funds’ yield on that investment.</p>
                        <p>The Funds do not expect to invest a significant portion of their assets in REITs but do not have any investment restrictions with respect to such investments.</p>

                        <b class="mod1">Income Risk</b>
                        <p>The income Common Shareholders receive from the Funds is based primarily on the dividends and interest it earns from its investments, which can vary widely over the short and long term. If prevailing market interest rates drop, distribution rates of the Funds’ preferred stock holdings and any bond holdings and Common Shareholder’s income from the Funds could drop as well. The Funds’ income also would likely be affected adversely when prevailing short-term interest rates increase and the Funds are utilizing leverage.</p>

                        <b class="mod1">Non-Investment Grade Securities Risk</b>
                        <p>The Funds’ investments in preferred stocks and bonds of below investment grade quality (commonly referred to as “high yield” or “junk bonds”), if any, are predominantly speculative because of the credit risk of their issuers. While offering a greater potential opportunity for capital appreciation and higher yields, preferred stocks and bonds of below investment grade quality entail greater potential price volatility and may be less liquid than higher-rated securities. Issuers of below investment grade quality preferred stocks and bonds are more likely to default on their payments of dividends/interest and liquidation value/principal owed to the Funds, and such defaults will reduce the Funds’ net asset value and income distributions. The prices of these lower quality preferred stocks and bonds are more sensitive to negative developments than higher rated securities. Adverse business conditions, such as a decline in the issuer’s revenues or an economic downturn, generally lead to a higher non-payment rate. In addition, such a security may lose significant value before a default occurs as the market adjusts to expected higher non-payment rates. The foregoing credit quality policies apply only at the time a security is purchased, and the Funds are not required to dispose of securities already owned by the Funds in the event of a change in assessment of credit quality or the removal of a rating.</p>

                        <b class="mod1">Interest Rate Risk </b>
                        <p>Interest rate risk is the risk that preferred stocks paying fixed dividend rates and fixed-rate debt securities will decline in value because of changes in market interest rates. When interest rates rise the market value of such securities generally will fall. The Funds’ investment in preferred stocks and fixed-rate debt securities means that the net asset value and price of the Common Shares may decline if market interest rates rise. Interest rates are currently low relative to historic levels. During periods of declining interest rates, an issuer of preferred stock or fixed-rate debt securities may exercise its option to redeem or prepay securities prior to maturity, which could result in the Funds’ having to reinvest in lower yielding debt securities or other types of securities. This is known as call or prepayment risk. During periods of rising interest rates, the average life of certain types of securities may be extended because of slower than expected payments. This may lock in a below market yield, increase the security’s duration, and reduce the value of the security. This is known as extension risk. Investments in debt securities with long-term maturities may experience significant price declines if long-term interest rates increase. This is known as maturity risk. The value of the Funds’ common stock investments may also be influenced by changes in interest rates.</p>

                        <b class="mod1">Hedging Strategy Risk</b>
                        <p>Certain of the investment techniques that the Funds may employ for hedging or, under certain circumstances, to increase income or total return will expose the Funds to risks. In addition to the hedging techniques described elsewhere (i.e., positions in Treasury Bond or Treasury Note futures contracts, use of options on these positions, positions in interest rate swaps, options thereon (“swaptions”), and credit derivatives), such investment techniques may include entering into interest rate and stock index futures contracts and options on interest rate and stock index futures contracts, purchasing and selling put and call options on securities and stock indices, purchasing and selling securities on a when-issued or delayed delivery basis, entering into repurchase agreements, lending portfolio securities and making short sales of securities “against the box.”</p>
                        <p>The Funds intend to comply with regulations of the Securities and Exchange Commission involving “covering” or segregating assets in connection with the Funds’ use of options and futures contracts.</p>

                        <p> There are economic costs of hedging reflected in the pricing of futures, swaps, options, and swaption contracts which can be significant, particularly when long-term interest rates are substantially above short-term interest rates, as is the case at present. The desirability of moderating these hedging costs will be a factor in Clough Capital’s choice of hedging strategies, although costs will not be the exclusive consideration in selecting hedge instruments. In addition, the Funds may select individual investments based upon their potential for appreciation without regard to the effect on current income, in an attempt to mitigate the impact on the Funds’ assets of the expected normal cost of hedging.</p>
                        <p>There may be an imperfect correlation between changes in the value of the Funds’ portfolio holdings and hedging positions entered into by the Funds, which may prevent the Funds from achieving the intended hedge or expose the Funds to risk of loss. In addition, the Funds’ success in using hedge instruments is subject to Clough Capital’s ability to predict correctly changes in the relationships of such hedge instruments to the Funds’ portfolio holdings, and there can be no assurance that Clough Capital’s judgment in this respect will be accurate. Consequently, the use of hedging transactions might result in a poorer overall performance for the Funds, whether or not adjusted for risk, than if the Funds had not hedged its portfolio holdings.</p>

                        <b class="mod1">Credit Risk</b>
                        <p>Credit risk is the risk that an issuer of a preferred or debt security will become unable to meet its obligation to make dividend, interest and principal payments. In general, lower rated preferred or debt securities carry a greater degree of credit risk. If rating agencies lower their ratings of preferred or debt securities in the Funds’ portfolio, the value of those obligations could decline. In addition, the underlying revenue source for a preferred or debt security may be insufficient to pay dividends, interest or principal in a timely manner. Any default by an issuer of a preferred or debt security could have a negative impact on the Funds’ ability to pay dividends on Common Shares. Even if the issuer on may negatively affect its credit rating or presumed creditworthiness. These developments would adversely affect the market value of the issuer’s obligations or the value of credit derivatives if the Funds have sold credit protection.</p>

                        <b class="mod1">Derivatives Risk</b>
                        <p>Derivative transactions (such as futures contracts and options thereon, options, swaps and short sales) subject the Funds to increased risk of principal loss due to imperfect correlation or unexpected price or interest rate movements. The Funds also will be subject to credit risk with respect to the counterparties to the derivatives contracts purchased by the Funds. If a counterparty becomes bankrupt or otherwise fails to perform its obligations under a derivative contract due to financial difficulties, the Funds may experience significant delays in obtaining any recovery under the derivative contract in a bankruptcy or other reorganization proceeding. The Funds may obtain only a limited recovery or may obtain no recovery in such circumstances. As a general matter, dividends received on hedged stock positions are characterized as ordinary income and are not eligible for favorable tax treatment. In addition, use of derivatives may give rise to short-term capital gains and other income that would not qualify for payments by the Funds of tax-advantaged dividends.</p>
                        <p>The Securities and Exchange Commission (SEC) recently adopted Rule 18f-4 under the Investment Company Act of 1940, as amended (1940 Act), which will regulate the use of derivatives for certain funds registered under the 1940 Act. Unless the Funds qualify as a “limited derivatives user” as defined in Rule 18f-4, the rule would, among other things, require the Funds to establish a comprehensive derivatives risk management program, to comply with certain value-at-risk based leverage limits, to appoint a derivatives risk manager and to provide additional disclosure both publicly and to the SEC regarding its derivatives positions. If the Funds qualify as a limited derivatives user, Rule 18f-4 would require the Funds to have policies and procedures to manage its aggregate derivatives risk. These requirements could have an impact on the Funds, including a potential increase in cost to enter into derivatives transactions and may require the Funds to alter, perhaps materially, its use of derivatives.</p>

                        <b class="mod1">Counterparty Risk</b>
                        <p>The Funds run the risk that the issuer or guarantor of a fixed income security, the counterparty to an over-the-counter derivatives contract, a borrower of the Funds’ securities or the obligor of an obligation underlying an asset-backed security will be unable or unwilling to make timely principal, interest, or settlement payments or otherwise honor its obligations. In addition, to the extent that the Funds use over-the- counter derivatives, and/or has significant exposure to a single counterparty, this risk will be particularly pronounced for the Funds.</p>

                        <b class="mod1">Small and Medium Cap Company Risk</b>
                        <p>Compared to investment companies that focus only on large capitalization companies, the Funds’ share price may be more volatile because it also invests in small and medium capitalization companies. Compared to large companies, small and medium capitalization companies are more likely to have (i) more limited product lines or markets and less mature businesses, (ii) fewer capital resources, (iii) more limited management depth, and (iv) shorter operating histories. Further, compared to large cap stocks, the securities of small and medium capitalization companies are more likely to experience sharper swings in market values, be harder to sell at times and at prices that Clough Capital believes appropriate, and offer greater potential for gains and losses.</p>

                        <b class="mod1">Leverage Risk</b>
                        <p>Leverage creates risks for holders of the Common Shareholders, including the likelihood of greater volatility of net asset value and market price of the Common Shares. There is a risk that fluctuations in the dividend rates on any preferred shares may adversely affect the return to the Common Shareholders. If the income from the securities purchased with such funds is not sufficient to cover the cost of leverage, the return on the Funds will be less than if leverage had not been used, and therefore the amount available for distribution to Common Shareholders as dividends and other distributions will be reduced and may not satisfy the level dividend rate distribution policy set by the Board of Trustees. Clough Capital in its best judgment nevertheless may determine to maintain the Funds’ leveraged position if it deems such action to be appropriate in the circumstances.</p>

                        <b class="mod1">Liquidity Risk</b>
                        <p>Restricted securities and other illiquid investments of the Funds involve the risk that the securities will not be able to be sold at the time desired by Clough Capital or at prices approximating the value at which the Funds is carrying the securities. Where registration is required to sell a security, the Funds may be obligated to pay all or part of the registration expenses, and a considerable period may elapse between the decision to sell and the time the Funds may be permitted to sell a security under an effective registration statement. If, during such a period, adverse market conditions were to develop, the Funds might obtain a less favorable price than prevailed when it decided to sell. Restricted securities for which no market exists and other illiquid investments are valued at fair value as determined in accordance with procedures approved and periodically reviewed by the Trustees of the Funds.</p>

                        <b class="mod1">Inflation Risk</b>
                        <p>Inflation risk is the risk that the purchasing power of assets or income from investment will be worth less in the future as inflation decreases the value of money. As inflation increases, the real value of the Common Shares and distributions thereon can decline. In addition, during any periods of rising inflation, dividend rates of preferred shares of the Funds would likely increase, which would tend to further reduce returns to Common Shareholders.</p>

                        <b class="mod1">Market Price of Shares</b>
                        <p>The shares of closed-end management investment companies often trade at a discount from their net asset value, and the Funds’ Common Shares may likewise trade at a discount from net asset value. The trading price of the Funds’ Common Shares may be less than the public offering price. The returns earned by Common Shareholders who sell their Common Shares below net asset value will be reduced.</p>

                        <b class="mod1">Management Risk</b>
                        <p>The Funds are subject to management risk because it is an actively managed portfolio. Clough Capital and the individual portfolio managers will apply investment techniques and risk analyses in making investment decisions for the Funds, but there can be no guarantee that these will produce the desired results.</p>

                        <b class="mod1">Market Disruption and Geopolitical Risk</b>
                        <p>The ongoing U.S. military and related actions in Iraq and Afghanistan and events in the Middle East and Ukraine, as well as the continuing threat of terrorist attacks, could have significant adverse effects on the U.S. economy, the stock market and world economies and markets. The Funds cannot predict the effects of similar events in the future on the U.S. economy and securities markets. These military actions and related events, including the conflicts in the Middle East, have led to increased short-term market volatility and may have long-term effects on U.S. and world economies and markets. Similar disruptions of the financial markets could impact interest rates, auctions, secondary trading, ratings, credit risk, inflation and other factors relating to the Common Shares.</p>

                        <b class="mod1">Pandemic Risks</b>
                        <p>An outbreak of COVID-19 respiratory disease caused by a novel coronavirus was first detected in late 2019 and subsequently spread globally in early 2020. The impact of the outbreak has been rapidly evolving, and cases of the virus have continued to be identified in most developed and emerging countries throughout the world. Many local, state, and national governments, as well as businesses, have reacted by instituting quarantines, border closures, restrictions on travel, and other measures designed to arrest the spread of the virus. The outbreak and public and private sector responses thereto have led to large portions of the populations of many nations working from home for indefinite periods of time, temporary or permanent layoffs, disruptions in supply chains, lack of availability of certain goods, and adversely impacted many industries. These circumstances are evolving, and further developments could result in additional disruptions and uncertainty. The impact of the coronavirus outbreak may last for an extended period of time and result in a substantial economic downturn. Pandemics, including the coronavirus outbreak, have resulted in a general decline in the global economy and negative effects on the performance of individual countries, industries, or sectors. Such negative impacts can be significant in unforeseen ways. Deteriorating economic fundamentals may in turn increase the risk of default or insolvency of particular companies, negatively impact market value, increase market volatility, cause credit spreads to widen, and reduce liquidity. All of these risks may have a material adverse effect on the performance and financial condition of the Funds’ investments, and on the overall performance of the Funds.</p>

                        <b class="mod1">Income Risk</b>
                        <p>The income Common Shareholders receive from the Funds is based primarily on the dividends and interest it earns from its investments, which can vary widely over the short and long term. If prevailing market interest rates drop, distribution rates of the Funds’ preferred stock holdings and any bond holdings and Common Shareholder’s income from the Funds could drop as well. The Funds’ income also would likely be affected adversely when prevailing short-term interest rates increase and the Funds are utilizing leverage.</p>

                        <b class="mod1">Preferred Securities Risk</b>
                        <p>In addition to credit risk, investment in preferred securities carries certain risks including:</p>

                        <p>●	Deferral Risk—Fully taxable or hybrid preferred securities typically contain provisions that allow an issuer, at its discretion, to defer distributions for up to 20 consecutive quarters. Traditional preferreds also contain provisions that allow an issuer, under certain conditions to skip (in the case of “noncumulative preferreds”) or defer (in the case of “cumulative preferreds”), dividend payments. If the Funds own a preferred security that is deferring its distributions, the Funds may be required to report income for tax purposes while it is not receiving any distributions.</p>

                        <p>●	Redemption Risk—Preferred securities typically contain provisions that allow for redemption in the event of tax or security law changes in addition to call features at the option of the issuer. In the event of a redemption, the Funds may not be able to reinvest the proceeds at comparable rates of return.</p>
                        <p>●	Limited Voting Rights—Preferred securities typically do not provide any voting rights, except in cases when dividends are in arrears beyond a certain time period, which varies by issue.</p>
                        <p>●	Subordination—Preferred securities are subordinated to bonds and other debt instruments in a company’s capital structure in terms of priority to corporate income and liquidation payments, and therefore will be subject to greater credit risk than those debt instruments.</p>
                        <p>●	Liquidity—Preferred securities may be substantially less liquid than many other securities, such as U.S. government securities, corporate debt or common stocks.</p>

                        <b class="mod1">Debt Securities Risk</b>
                        <p>In addition to credit risk, investment in debt securities carries certain risks including:</p>
                        <p>●	Redemption Risk—Debt securities sometimes contain provisions that allow for redemption in the event of tax or security law changes in addition to call features at the option of the issuer. In the event of a redemption, the Funds may not be able to reinvest the proceeds at comparable rates of return.</p>
                        <p>●	Limited Voting Rights—Debt securities typically do not provide any voting rights, except in cases when interest payments have not been made and the issuer is in default.</p>
                        <p>●	Liquidity—Certain debt securities may be substantially less liquid than many other securities, such as U.S. government securities or common stocks.</p>

                        <b class="mod1">Convertible Securities Risk</b>
                        <p>The value of a convertible security is a function of its “investment value” (determined by its yield in comparison with the yields of other securities of comparable maturity and quality that do not have a conversion privilege) and its “conversion value” (the security’s worth, at market value, if converted into the underlying common stock). The investment value of a convertible security is influenced by changes in interest rates, with investment value declining as interest rates increase and increasing as interest rates decline. The credit standing of the issuer and other factors may also have an effect on the convertible security’s investment value. The conversion value of a convertible security is determined by the market price of the underlying common stock. If the conversion value is low relative to the investment value, the price of the convertible security is governed principally by its investment value. Generally, the conversion value decreases as the convertible security approaches maturity. To the extent the market price of the underlying common stock approaches or exceeds the conversion price, the price of the convertible security will be increasingly influenced by its conversion value. A convertible security generally will sell at a premium over its conversion value by the extent to which investors place value on the right to acquire the underlying common stock while holding a fixed- income security.</p>
                        <p>A convertible security may be subject to redemption at the option of the issuer at a price established in the convertible security’s governing instrument. If a convertible security held by the Funds is called for redemption, the Funds will be required to permit the issuer to redeem the security, convert it into the underlying common stock or sell it to a third party. Any of these actions could have an adverse effect on the Funds’ ability to achieve its investment objective.</p>

                        <b class="mod1">Short Sales Risk</b>
                        <p>Short-selling involves selling securities which may or may not be owned and borrowing the same securities for delivery to the purchaser, with an obligation to replace the borrowed securities at a later date. If the price of the security sold short increases between the time of the short sale and the time the Funds replace the borrowed security, the Funds will incur a loss; conversely, if the price declines, the Funds will realize a capital gain. Any gain will be decreased, and any loss will be increased, by the transaction costs incurred by the Funds, including the costs associated with providing collateral to the broker-dealer (usually cash and liquid securities) and the maintenance of collateral with its Custodian. Although the Funds’ gain is limited to the price at which it sold the security short, its potential loss is theoretically unlimited.</p>
                        <p>Short-selling necessarily involves certain additional risks. However, if the short seller does not own the securities sold short (an uncovered short sale), the borrowed securities must be replaced by securities purchased at market prices in order to close out the short position, and any appreciation in the price of the borrowed securities would result in a loss. Uncovered short sales expose the Funds to the risk of uncapped losses until a position can be closed out due to the lack of an upper limit on the price to which a security may rise. Purchasing securities to close out the short position can itself cause the price of the securities to rise further, thereby exacerbating the loss. There is the risk that the securities borrowed by the Funds in connection with a short-sale must be returned to the securities lender on short notice. If a request for return of borrowed securities occurs at a time when other short-sellers of the security are receiving similar requests, a “short squeeze” can occur, and the Funds may be compelled to replace borrowed securities previously sold short with purchases on the open market at the most disadvantageous time, possibly at prices significantly in excess of the proceeds received at the time the securities were originally sold short.</p>
                        <p>In September 2008, in response to spreading turmoil in the financial markets, the SEC temporarily banned short selling in the stocks of numerous financial services companies, and also promulgated new disclosure requirements with respect to short positions held by investment managers. The SEC’s temporary ban on short selling of such stocks has since expired, but should similar restrictions and/or additional disclosure requirements be promulgated, especially if market turmoil occurs, the Funds may be forced to cover short positions more quickly than otherwise intended and may suffer losses as a result. Such restrictions may also adversely affect the ability of the Funds to execute its investment strategies generally. Similar emergency orders were also instituted in non-U.S. markets in response to increased volatility. The Funds’ ability to engage in short sales is also restricted by various regulatory requirements relating to short sales.</p>


                        <b class="mod1">Anti-Takeover Provisions</b>
                        <p>The Funds’ Declaration of Trust includes provisions that could have the effect of inhibiting the Funds’ possible conversion to open-end status and limiting the ability of other entities or persons to acquire control of the Funds or the Board of Trustees. In certain circumstances, these provisions might also inhibit the ability of shareholders to sell their shares at a premium over prevailing market prices.</p>

                        <b class="mod1">Portfolio Turnover Risk</b>
                        <p>The techniques and strategies contemplated by the Funds might result in a high degree of portfolio turnover. The Funds cannot accurately predict its securities portfolio turnover rate, but anticipates that its annual portfolio turnover rate will exceed 100% under normal market conditions, although it could be materially higher under certain conditions. Higher portfolio turnover rates could result in corresponding increases in brokerage commissions and generate short-term capital gains taxable as ordinary income.</p>

                        <spacer></spacer>

                    </div>

                </div>

            </main>
            <Footer />
        </>
    );
}

export default Risk;